//variables
$file-uploader__primaryColor: rgb(114, 191, 167);
$file-uploader__primaryColor--hover: lighten($file-uploader__primaryColor, 15%);
$file-uploader__black: #242424;
$file-uploader__error: rgb(214, 93, 56);

.custom-files {
  .tabs .tab {
    width: 33.33% !important;
  }

  .file-uploader {
    background-color: #0575e60f;
    border-radius: 0px;
    color: $file-uploader__black;
    border: 2px solid #0575e6;
  }
  .custom_file-title {
    text-align: left;
    font-family: "PT Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    color: #0575e6;
    text-transform: uppercase;
    background: #f0f7fe;
    border: 1px solid;
    padding: 15px;
    cursor: pointer;
    &.extended:after {
      content: "▲";
      float: right;
    }
    &.not-extended:after {
      content: "▼";
      float: right;
    }
  }
  .extended-files {
    display: flex;
  }
  .not-extended-files {
    display: none;
  }
  form.file-uploader .fa-paperclip {
    color: #0575e6;
    font-size: 26px;
    position: absolute;
    top: 10px;
    right: 35px;
    background: white;
    padding: 7px 10px;
    border: 2px solid;
    border-radius: 50%;
  }

  .file-uploader__message-area {
    font-size: 18px;
    padding: 1em;
    text-align: center;
    color: #0575e6;
    height: 90px;
    p {
      margin-bottom: 0 !important;
    }
    input {
      color: #0575e6;
      border-color: #0575e6;
    }
  }

  .file-list {
    background-color: lighten($file-uploader__primaryColor, 45%);
    font-size: 16px;
  }

  .file-list__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-list li {
    height: 50px;
    line-height: 50px;
    margin-left: 0.5em;
    border: none;
    overflow: hidden;
  }

  .removal-button {
    width: 20%;
    border: none;
    background-color: $file-uploader__error;
    color: white;

    &::before {
      content: "X";
    }
    &:focus {
      outline: 0;
    }
  }

  .file-chooser {
    padding: 5px 1em;
    height: 75px;
    transition: background-color 1s, height 1s;
    & p {
      font-size: 18px;
      padding-top: 1em;
    }
  }

  //layout
  .file-uploader {
    max-width: 400px;
    height: auto;
    margin: 2em auto;

    & * {
      display: block;
    }
    & input[type="submit"] {
      margin-top: 0.5em;
    }
  }

  .file-list {
    margin: 0 auto;
    max-width: 90%;
  }

  .file-list__name {
    max-width: 70%;
    float: left;
  }

  .file-list li {
    @extend %clearfix;
  }

  .removal-button {
    display: inline-block;
    height: 100%;
    float: right;
  }

  .file-chooser {
    margin: 0 auto;
    label {
      margin: 0;
      width: auto;
      max-width: 300px;
      height: auto;
      border: none;
      color: #fff;
      cursor: pointer;
      text-align: center;
      span {
        padding: 5px;
        background-color: #929292;
      }
      span.valid {
        background-color: #18cabe;
      }
    }
  }

  .file-chooser__input {
    margin: 0 auto;
    display: none;
  }

  .file-uploader__submit-button {
    width: 100%;
    border: none;
    font-size: 18px;
    padding: 10px;
    background-color: #0575e6;
    color: white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &.with-default {
      width: 75% !important;
      display: inline-block !important;
    }
  }
  .default-show {
    display: inline-block !important;
    background: #f0f7fe;
    width: 25%;
    padding: 15px;
    text-align: center;
    cursor: pointer;
  }

  .file-uploader__actions-buttons,
  .loading {
    margin-top: 10px;
  }

  .file-uploader__actions-buttons span,
  .file-uploader__actions-buttons a {
    display: inline-block !important;
    width: 33.33%;
    text-align: center;
    border: none;
    font-size: 18px;
    padding: 14px;
    color: #495057;
    cursor: pointer;
    text-decoration: none;
    background: #fff;
    border-top: 2px solid #0575e6;
    &.edit {
      color: #ffc107c7;
      border-bottom-left-radius: 0px;
      border-right: 2px solid #0575e6;
    }
    &.del {
      color: #ff3547c7;
      border-right: 2px solid #0575e6;
    }
    &.show {
      color: #18cabec7;
      border-bottom-right-radius: 0px;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .upload-loading {
    opacity: 0.5;
  }

  .loading .spinner {
    background: #f2f2f2;
    text-align: center;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    svg {
      margin: auto;
    }
  }

  .upload-spinner {
    svg {
      margin: auto;
    }
  }
  //layout
  .file-uploader {
    @extend %clearfix;
  }

  //utility

  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .hidden {
    display: none;
    & input {
      display: none;
    }
  }

  .error {
    color: $file-uploader__error;
  }

  //reset
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
  }
}

.file-type-card {
  background: #0575e61f;
  border: 2px solid #0575e6;
  margin: 15px 0;
  padding: 40px 0;
  border-radius: 5px;
  color: #0575e6;
  box-shadow: 1px 2px 4px 1px #cecece;
  cursor: pointer;
  &:hover {
    background: linear-gradient(88deg, #18cabe, #13b4ca);
    color: white !important;
  }
  a {
    text-decoration: none !important;
    display: block;
    color: #0575e6;
    :hover {
      color: white;
    }
  }
  .card-body {
    text-align: center;
    .fa {
      font-size: 40px;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    .title {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "Open Sans", sans-serif;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
      line-height: 21px;
      font-weight: 700;
    }
  }
}
