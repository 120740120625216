/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

.StripeElement {
  border-bottom: inherit;
  border-radius: inherit;
  outline: inherit;
  width: 100%;
  font-size: inherit;
  box-sizing: inherit !important;

  height: 40px !important;
  padding: 10px 12px !important;

  color: #32325d;
  background-color: white !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;

  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
  -webkit-transition: box-shadow 150ms ease !important;
  transition: box-shadow 150ms ease !important;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.sepaBtn {
  height: 36px;
  width: 100%;
  max-width: 400px;
  margin-top: 5px;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  background: #556cd6;
}
