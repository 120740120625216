@import url("https://fonts.googleapis.com/css?family=Crete+Round&display=swap");

.content {
  display: flex;
  min-height: 300px;
}

.right-content .body {
  padding: 15px;
}

.left-content {
  width: 60px;
  background: white;
  .logo {
    display: flex;
    text-decoration: none;
    font-family: "Crete Round", serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 1;
    padding: 5px;
    padding-left: 10px;
  }
  .small-logo {
    background-image: url("/images/logo/logoImg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 50px;
    position: relative;
    top: 4px;
  }

  .large-logo {
    display: none;
  }

  &.extend {
    width: 200px;

    .large-logo {
      display: block;
      background-image: url("/images/logo/logoText.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 160px;
    }
  }
}

.right-content {
  background: #f6f8fa;
  width: 100%;
}
