.custom-input {
  margin-bottom: 20px;
  div.input {
    position: relative;

    > span.label {
      margin-bottom: 5px;
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
      letter-spacing: 0;
      color: #000000;
      text-transform: uppercase;
      opacity: 1;
    }

    > input[type="text"],
    > input[type="email"],
    > input[type="number"],
    > .react-datepicker-wrapper input[type="text"] {
      padding: 5px 0px 5px 0px;
      background: white;
      border: 1px solid #e6e6e6;

      &:focus {
        outline: 0;
        background: #0575e6;
        color: white;

        & + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none;
        }
      }
    }

    > .react-datepicker-wrapper {
      width: 100%;
    }

    > select.select {
      background-color: white;
      padding: 10px 0px 10px 0px;
      width: 100%;
      border: 1px solid #e6e6e6;
      &:focus {
        outline: 0;
        background-color: #0575e6;
        color: white;
        & + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none;
        }
      }
    }

    > .radio {
      background-color: white;
      padding: 10px 10px 10px 10px;
      width: 100%;
      border: 1px solid #e6e6e6;
      span {
        margin-right: 15px;
        margin-left: 5px;
      }
      &:hover {
        outline: 0;
        background: #0575e6;
        color: white;
        & + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none;
        }
      }
    }

    .requirements {
      color: #999;
      transition: 0.28s;
      max-height: 0;
      overflow: hidden;
      font-style: italic;
      span.badge {
        margin-right: 10px;
        font-size: 12px !important;
        font-weight: 400;
        padding: 3px !important;
        background: white;
        color: white;
        width: 50px;

        &.optional {
          background: #e67e22;
        }
        &.required {
          background: #e74c3c;
        }
      }
    }
  }
}

.c-error .c-validation {
  color: #e74c3c !important;
  padding: 3px 10px !important;
  border-radius: 0 !important;
  position: relative;
  display: inline-block !important;
  border: 1px solid #cccccc;
  margin-top: 10px;
  font-size: 12px;
  background: #e4e4e4;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #cccccc;
    position: absolute;
    top: -10px;
  }
}

.c-error .label:after {
  content: "*";
  color: #e74c3c;
}

button.submit,
.direction {
  display: block;
  width: 100%;
  padding: 5px 0;
  background: #0575e6;
  color: white;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  border-radius: $borderRadius;
  text-transform: uppercase;
}

.direction {
  background: white !important;
  color: #0575e6 !important;
  border: 1px solid #0575e6 !important;
  margin-top: 5px;
  &:hover {
    background: #0575e65a !important;
    color: white !important;
  }
}

.no-bg.custom-input div.input > .radio:hover {
  background: #eee !important;
  color: #212529 !important;
}

.generate-btn {
  display: flex;
  width: 100%;
  padding: 5px 0;
  background: #031c79;
  color: white;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  margin: 5px 0;
  min-height: 60px;
  border-radius: $borderRadius;
  &.blue-bg {
    background: #0575e6 !important;
  }
  span {
    margin: auto;
    text-align: center;
  }
}

$primary: #00b6e9;
$gray: #232323;
$sans: "Source Sans Pro";

.download-btn {
  padding: 0.5rem 1rem;
  width: 100%;

  background: $primary;
  display: inline-block;
  color: $gray;
  font: 200 20px $sans;
  text: {
    decoration: none;
    align: left;
  }
  transition: 0.5s linear all;

  &:hover {
    background: lighten($primary, 20%);
  }
  i {
    color: white;
    margin-right: 10px;
  }
}

button.validate-btn,
button.remove-btn,
button.add-ecoBen {
  color: white;
  border: none;
  border-radius: $borderRadius;
  width: 200px;
  padding: 5px;
  i {
    margin-right: 5px;
  }
}

button.add-btn {
  padding: 15px 40px;
  background: #e5f1fd !important;
  border: none;
  border-radius: 5px;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  box-shadow: 0 0 4px 0 #ccc;
  i {
    margin-right: 5px;
  }
}

.validate-benef {
  position: relative;
  width: 100%;
}

.validate-benef i {
  float: right;
  padding: 10px 20px;
  border: 1px solid #333;
  background: #8cf0a3;
  color: #333;
  cursor: pointer;
}

.inlineFlex {
  display: inline-flex;
}
.show-benef i,
.remove-benef i {
  padding: 10px 20px;
  border: 1px solid #333;
  color: #333;
  cursor: pointer;
  display: inline-block;
}
.show-benef i {
  background: #e5f1fd;
}

.remove-benef i {
  background: #ff3547;
}
.benef {
  background: #e5f1fd;
  left: 15px;
  line-height: 2;
}
button.add-btn {
  background: #0575e6;
}

button.remove-btn {
  color: #ff3547;
  border: 1px solid;
}

button.validate-btn {
  color: #0575e6;
  border: 1px solid;
}

button.add-ecoBen {
  background: white;
  color: #0575e6;
  border: 1px solid;
}
// Radio

.no-bg,
.inline-language,
.rgpd,
.new-mail {
  .radio-container {
    position: relative;
    padding-left: 24px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .radio-container .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ccc;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #0575e6;
  }

  /* When the checkbox is checked, add a blue background */
  .radio-container .checkmark.checked {
    background-color: #0575e6;
  }

  /* Style the checkmark/indicator */
  .radio-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    content: "";
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .radio-container .checkmark.checked:after {
    display: block;
  }
}

.inline-language {
  margin-left: 15px;
  label {
    margin-right: 15px;
  }
}
.no-padding {
  padding: 0px !important;
}

.tiny-sb {
  display: grid;
  flex-direction: column;
  grid-template-columns: auto 45px;
}
