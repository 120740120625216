.Agency {
  .agency-container {
    width: 100%;
    .agency-sup-information {
      display: none;
      &.show {
        display: block;
      }
    }

    .agency-title {
      text-align: left;
      font-family: "Arima Madurai", cursive;
      letter-spacing: 0;
      color: #000000;
      opacity: 1;
    }
    .agency-sub-title {
      font-family: "PT Sans", sans-serif;
    }
    .section-title {
      text-align: left;
      font-family: "PT Sans", sans-serif;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      color: #0575e6;
      text-transform: uppercase;
    }
    .select {
      border: 1px solid rgba(128, 128, 128, 0.2);
      display: block;
      line-height: 1;
      padding: 10px;
      width: 100%;
    }
  }

  .agency-toggle {
    text-align: center;
    border: 1px solid #c8c8c8;
    cursor: pointer;
    &:hover {
      background: #f2f2f2;
    }
  }

  .end-subscription {
    color: #13b4ca;
    cursor: pointer;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }

  label span span {
    color: #ff4444;
  }
  table.table.orders {
    background: white;
    thead {
      border-top: 3px solid #0575e6 !important;
    }
  }

  .navigation {
    border: 1px solid #0575e6;
    border-radius: $borderRadius;
  }

  .tabs .tab {
    display: inline-block;
    width: 25%;
    text-align: center;
    background: white;
    padding: 10px 0;
    color: #0575e6;
    font-size: 16px;
    border-left: 1px solid #0575e6;
    border-bottom: 1px solid #0575e6;
    cursor: pointer;

    font-family: "PT Sans", sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;

    &:first-child {
      border-left: none !important;
    }
    &.active {
      color: white;
      background: #0575e6;
    }
    &:first-child {
      border-top-left-radius: $borderRadius;
    }
    &:last-child {
      border-top-right-radius: $borderRadius;
    }
  }
  .tabs.full .tab {
    width: 20% !important;
  }
  .nav-content {
    padding: 25px;
    background: white;
    border-radius: $borderRadius;
    .content-tab {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

.table-filter-icon.selected {
  border-top-color: #fb9678 !important;
  box-shadow: inset 0 4px #fb9678 !important;
}

.agency-folders .basic-table thead th {
  border-right: 1px solid;
}

.chart-item {
  background: white;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #dee2e6;
  .chart-title {
    color: #333;
    font-weight: 500;
    text-decoration: underline;
    padding-bottom: 25px;
  }

  .chart {
    height: 200px;
  }
  span.chart-number {
    margin-left: 10px;
    background: #333333;
    color: white;
    padding: 0px 5px;
    border-radius: 5px;
  }
}
