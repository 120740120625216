.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  max-width: 400px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #6c757d;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin: auto;
  margin-bottom: 25px;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe-tabs .tab {
  padding: 10px;
  border: 1px solid #0575e6;
  color: #0575e6;
  cursor: pointer;
}

.stripe-tabs .tab.active {
  background: #0575e6;
  color: white;
}

.sepaForm {
  max-width: 400px;
  margin: auto;
}
.mandate-acceptance {
  text-align: justify;
  font-size: 12px;
}
