.homePage {
  position: relative;
  @media (min-width: 500px) {
    top: -15px;
    left: -15px;
    width: calc(100% + 30px);
  }

  .carousel .slide {
    text-align: inherit !important;
  }
  .slider-block {
    position: absolute;
    top: 100px;
    margin-left: 30px;
    @media (max-width: 767px) {
      top: 30px !important;
    }
    @media (max-width: 600px) {
      top: 10px !important;
      margin-left: 10px;
    }
    .title {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 20px;
      color: #e67e22;
      letter-spacing: 2px;

      background: #ffffffb3;
      padding: 0 10px;
      span:nth-child(2) {
        color: #2c3e50;
      }

      @media (max-width: 992px) {
        font-size: 28px !important;
        font-weight: 700 !important;
      }
      @media (max-width: 767px) {
        font-size: 22px !important;
        font-weight: 600 !important;
      }
      @media (max-width: 600px) {
        font-size: 18px !important;
        font-weight: 600 !important;
        letter-spacing: 1px;
      }
      @media (max-width: 500px) {
        font-size: 15px !important;
        font-weight: 600 !important;
        letter-spacing: 0.5px;
      }
    }
    .list {
      width: 100%;
      ul li {
        font-size: 20px;
        font-weight: 600;
        color: #354b60;
        margin-bottom: 20px;
        @media (max-width: 767px) {
          font-size: 18px !important;
        }
        @media (max-width: 600px) {
          font-size: 15px !important;
        }

        @media (max-width: 500px) {
          font-size: 13px !important;
          margin-bottom: 5px;
        }
      }
    }
  }

  .carousel .slide .legend {
    text-decoration: none;
    width: fit-content !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    background: white !important;
    opacity: 1 !important;
    color: #2c3e50 !important;
    font-weight: 500;
    border: 2px solid;
    margin-left: 0 !important;
    left: inherit !important;
    right: 30px;
  }

  .section {
    padding: 50px 25px;
    &:nth-child(even) {
      background-color: #f9f9f9;
    }
    &:nth-child(odd) {
      background-color: #fdf3f3;
    }
  }
  .silder-top {
    .carousel .slide img {
      max-height: 500px;
    }
  }

  .introduction,
  .concept,
  .advantages,
  .subscriptions {
    position: relative;
    .title {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      color: #e67e22;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 2px;
      span:nth-child(2) {
        color: #2c3e50;
      }
    }
    .body {
      margin-top: 25px;
      p {
        color: #6f6f6f;
        letter-spacing: 0.5px;
        line-height: 26px;
        text-align: justify;
      }
    }
    img.img {
      max-height: 245px;
      max-width: 100%;
    }
  }

  .color_border {
    background-color: #e67e22;
    height: 3px;
    width: 110px;
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      background-color: #fff;
      width: 15px;
      height: 3px;
      top: 0;
      position: absolute;
      right: 30px;
      z-index: 999;
    }
    &:after {
      background-color: #2c3e50;
      content: "";
      height: 3px;
      right: 0;
      position: absolute;
      top: 0;
      width: 30px;
      z-index: 9;
    }
  }

  .usp {
    .placeholder {
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      color: #373737;
      letter-spacing: 0.5px;
      font-size: 18px;
      text-align: center;
    }
    .usp-card {
      border-top: 3px solid #0575e6;
      margin: 15px 0;
      padding: 30px 0;
      border-radius: 5px;
      color: #0575e6;
      box-shadow: 1px 2px 4px 1px #cecece;
      height: 326px;
      background: white;
      &:hover {
        background: linear-gradient(88deg, #18cabe, #13b4ca);
        color: white !important;
      }
      .usp-body {
        padding: 0 20px;
        .fa {
          font-size: 30px;
          margin-top: 5px;
          margin-bottom: 20px;
          padding: 15px;
          border: 1px solid;
          border-radius: 50%;
        }
        .title {
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-family: "Open Sans", sans-serif;
          -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
          line-height: 21px;
          font-weight: 700;
        }
        .body {
          padding: 0 !important;
        }
      }
    }
    .usp-action {
      text-align: center;
      margin-bottom: 25px;
      .link {
        background: #0575e6;
        color: white;
        padding: 15px;
        display: block;
        text-decoration: none;
        &:hover {
          background: linear-gradient(88deg, #13b4ca, #18cabe);
        }
      }
    }
  }

  .testimonials {
    background-image: url("/images/sliders/slider1.jpg");
    position: relative;
    height: 425px;
    width: 100%;

    @media (max-width: 450px) {
      height: 600px;

      .carousel.slide {
        padding: 0;
      }
    }

    @media (max-width: 500px) {
      height: 565px;
    }
    @media (max-width: 767px) {
      height: 475px;
    }

    .title {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      color: white;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 2px;
      @media (max-width: 450px) {
        font-size: 20px;
      }
      span:nth-child(2) {
        color: #e67e22;
      }
    }

    .carousel .carousel-indicators li {
      position: relative;
      top: 30px;
    }

    .section {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0;
      @media (max-width: 450px) {
        padding-top: 20px;
      }

      .single_testimonial_area {
        margin: 0 10px;
        .testimonial_author_name {
          text-align: right;
          h5 {
            margin-bottom: 5px;
            letter-spacing: 1px;
            color: #e67e22;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
          }
          h6 {
            color: #fff;
            font-weight: 300;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin: 0;
          }
        }

        .testimonial_text {
          background-color: #fff;
          border-radius: 6px;
          color: #999;
          margin: 30px 0;
          padding: 40px 30px 30px 30px;
          text-align: center;
          position: relative;
          z-index: 1;
          @media (max-width: 450px) {
            height: 285px;
            padding: 40px 10px 30px 10px;
          }
          p {
            letter-spacing: 0.5px;
            position: relative;
            margin: 0;
            z-index: 1;
            @media (max-width: 450px) {
              height: 225px;
              overflow-x: auto;
            }
            &:before {
              color: #1abc9c;
              content: "\f10e";
              font-family: fontawesome;
              font-size: 70px;
              position: absolute;
              bottom: -50px;

              right: 0;
              z-index: -5;
              opacity: 0.2;
            }
          }
          .testimonial_author_thumb {
            height: 80px;
            left: 30px;
            position: absolute;
            top: -40px;
            width: 80px;
            z-index: 9;
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .advantages,
  .comparison {
    h5 {
      color: #2c3e50;
    }
    .description {
      color: #6f6f6f;
      letter-spacing: 0.5px;
      line-height: 26px;
    }
    ul {
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 1px 4px 1px #dee2e6;
      border-top: 3px solid #2c3e50;
      background: white;
    }
    ul li {
      letter-spacing: 1px;
      text-transform: capitalize;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;

      margin: 0;
      padding: 10px 0 10px 40px;
      list-style: none;
      background-image: url("/images/shared/check1.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 25px;
    }
  }

  .comparison {
    .comparison-action {
      text-align: center;
      margin-bottom: 25px;
      .link {
        background: #0575e6;
        color: white;
        padding: 15px;
        display: block;
        text-decoration: none;
        &:hover {
          background: linear-gradient(88deg, #13b4ca, #18cabe);
        }
      }
    }
    .placeholder {
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      color: #373737;
      letter-spacing: 0.5px;
      font-size: 18px;
      text-align: center;
    }
    ul {
      margin-top: 30px;
      min-height: 320px;
      padding-top: 30px;
    }
    li {
      letter-spacing: 0.5px !important;
    }
    .negatif {
      border-top: 3px solid #e67e22 !important;
      li {
        font-size: 13px;
        padding-bottom: 0px !important;
        background-image: url("/images/shared/minus1.png") !important;
      }
      .sub {
        font-size: 11px;
        color: #7a7a7a;
        padding-left: 40px;
        margin-bottom: 10px;
      }
    }
    .positif {
      li {
        margin-bottom: 20px !important;
      }
      div {
        float: right;
      }
    }
  }

  .contact {
    border-bottom: 1px solid #e7862f;
    .header {
      background-image: url("/images/shared/contact.jpg");
      height: 150px;
      width: 100%;
      background-size: cover;
      position: relative;
      background-position-y: 80%;
      z-index: 1;
      &:after {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        background-color: rgba(246, 246, 246, 0.7);
      }
      h3.title {
        color: #e67e22;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        padding: 65px 0px 10px 0px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .information {
      background: #e1e1e2;
      padding: 30px 30px;

      .section_heading {
        margin-bottom: 15px;
        padding-top: 15px;
        h4 {
          font-family: "Open Sans", sans-serif;
          text-transform: uppercase;
          color: #373737;
          letter-spacing: 1px;
          font-size: 18px;
        }
      }
      .footer_single_contact_info {
        margin-bottom: 25px;
        position: relative;
        i {
          color: #e67e22;
          font-size: 20px;
          position: absolute;
          top: 2px;
          left: 0;
          z-index: 1;
        }
        p,
        a {
          color: #373737;
          display: block;
          font-size: 13px;
          letter-spacing: 1px;
          margin-left: 30px;
          margin-bottom: 0;
        }
      }
    }
    .form {
      padding: 40px 30px 10px;
      input,
      textarea {
        background: #e1e1e2;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border: 1px solid #6c757d;
        color: #e7862f;
      }
      textarea {
        height: 75px;
      }
      button {
        background: #e1e1e2;
        padding-left: 35px;
        padding-right: 35px;
        border: 1px solid #6c757d;
        float: right;
        color: #6c757d;
      }
    }
  }
}
