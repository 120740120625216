.navigation-menu{
  padding: 40px 0;
  li {
    list-style: none;
    a{
      display: flex;
      text-decoration: none;
      color: #8c8c8c;
      padding: 15px;
      &:hover{
        background: #f6f8fa;
      }
    }
  }
  .navigation-icon{
    padding: 0 6px;
    position: relative;
  }
  .navigation-text {
    display: none !important;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
    letter-spacing: 0;
    font-family: 'PT Sans', sans-serif;
    color: black;
  }
}

.extend{
  .navigation-text {
    display: block !important;
  }
}

.extend li[data-title] {
  &:hover::before, &:hover::after{
    display: none !important;
  }
}

.navigation-menu {
  li[data-title] {

    position: relative;
    cursor: help;
  }

  li[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    left: 65px;
    top: 5px;
    display: inline-block;
    padding: 10px;
    border-radius: 2px;
    background: linear-gradient(88deg, #13b4ca, #18cabe);;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
    z-index: 5;
  }
  li[data-title]:hover::after {
    content: '';
    position: absolute;
    left: 49px;
    top: 15px;
    display: inline-block;
    color: #fff;
    border: 8px solid transparent;
    border-right: 8px solid #13b4ca;
  }
}
