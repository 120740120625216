.subscription {
  .price-list {
    margin-top: 30px;
    margin-bottom: 30px;
    .card {
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      color: #333;
      position: relative;
      height: auto;
      background: white;
      border-radius: 3px;
      margin: 0 auto;
      padding: 25px 20px;
      box-shadow: none;
      transition: 0.5s;
      overflow: hidden;
      border: 1px solid #e2e2e2;
      border-top: 4px solid;
      margin-bottom: 20px;

      &:hover span.action {
        background: #333333aa;
        color: white;
      }

      .switch {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 15px;
        cursor: pointer;
        background: #8ecdff;
      }
    }

    .col-sell,
    .col-rent {
      .card {
        border-top-color: #8ecdff;
        span.action {
          background: #8ecdff;
          color: white;
        }
        .active.action-container {
          background: #8ecdff63;
        }
      }
    }
    .col-sell_rent {
      .card {
        border-top-color: #fba7ae;
        span.action {
          background: #fba7ae;
          color: white;
        }
        .active.action-container {
          background: #fba7ae63;
        }
      }
    }
    .col-sell_rent_media {
      .card {
        border-top-color: #b883ff;
        span.action {
          background: #b883ff;
          color: white;
        }
        .active.action-container {
          background: #b883ff63;
        }
      }
    }

    .title h2 {
      position: relative;
      padding: 0;
      font-size: 28px;
      z-index: 2;
    }
    .price {
      .solde-price {
        display: none;
      }
      &.solde .solde-price {
        display: inline-block;
      }
    }

    .price.solde {
      .origin-price {
        font-size: 15px;
        text-decoration: line-through;
      }
    }

    .price {
      position: relative;
      z-index: 2;
      .total {
        position: relative;
        top: -15px;
        font-size: 13px;
        .origin-price {
          padding-right: 5px;
        }
      }
    }
    .price h4 .origin-price,
    .price h4 .solde-price {
      margin: 0;
      padding: 10px 0 20px 0;
      font-size: 50px;
    }
    .option {
      position: relative;
      z-index: 2;
      display: contents;

      .radio-block {
        display: block;
        z-index: 999999;
        cursor: pointer;
        &:hover {
          background: #9eccf133;
        }
      }
      span.radio:before {
        content: "";
        position: relative;
        border-color: #495057;
        border-style: solid;
        height: 20px;
        top: 3px;
        width: 20px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 50%;
      }
      span.radio.check:before {
        background: #9eccf1;
      }

      span.radio.check {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background: #495057;
          border-radius: 50%;
          left: 4px;
          top: 4.5px;
        }
      }
    }
    .option ul {
      margin: 0;
      padding: 0;
    }
    .option ul li {
      margin: 0 0 10px;
      padding: 0;
      list-style: none;
      font-size: 16px;
      i {
        margin-right: 5px;
      }
    }
    .card div span.action {
      position: relative;
      z-index: 2;
      width: 185px;
      height: 40px;
      display: block;
      line-height: 40px;
      border-radius: 0px;
      font-size: 16px;
      text-align: center;
      margin: auto;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  .payement {
    margin-top: 30px;
    margin-bottom: 30px;
    .card {
      position: relative;
      height: auto;
      margin: 0 auto;
      box-shadow: none;
      transition: 0.5s;
      overflow: hidden;
      border-radius: 3px;
      background: white;
      padding: 25px 20px;
      &.sell,
      &.rent {
        border: none;
        border: 1px solid #e4e4e4;
        border-top: 3px solid #8ecdff;
      }
      &.sell_rent {
        border: none;
        border: 1px solid #e4e4e4;
        border-top: 3px solid #fba7ae;
      }
      &.sell_rent_media {
        border: none;
        border: 1px solid #e4e4e4;
        border-top: 3px solid #b883ff;
      }
    }
  }
  .card-bottom {
    height: 36px;
    width: 100%;
    max-width: 400px;
    margin-top: 5px;
    border-radius: 5px;
    color: #fff;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
    background: #556cd6;
  }
}
.update-currentOrder {
  background: #18cabe1f;
  padding: 1rem 15px;
  border: 1px solid #0575e6 !important;
  border-top: 3px solid #0575e6 !important;
  margin-bottom: 10px;
  position: relative;
  @media (max-width: 767px) {
    padding-bottom: 70px;
  }

  .price {
    float: right;
    position: relative;
    top: -40px;
    font-size: 18px;
    font-weight: 700;
  }

  .option {
    display: inline-block;
    @media (max-width: 767px) {
      display: block;
    }
    ul {
      list-style-type: none;
      padding-left: 10px;
      margin-bottom: 0;
      li {
        font-size: 13px;
        color: #8c8c8c;
        font-style: italic;
        i {
          margin-right: 5px;
        }
      }
    }
  }

  .upgrade-btn {
    background: #13b4ca;
    padding: 10px;
    width: 180px;
    text-align: center;
    color: white;
    border: 1px solid #0575e6;
    display: inline-block;
    position: absolute;
    right: 15px;
    margin-top: 15px;
    cursor: pointer;
  }
}
.subscription-container {
  background: white;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: $borderRadius;
}

.price-list {
  .special-card {
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
    height: 570px;
    &.is-flipped {
      transform: translateX(-100%) rotateY(-180deg);
    }
    .card {
      position: absolute;
      backface-visibility: hidden;
      width: calc(100% - 30px);
    }
    .card-rent {
      transform: rotateY(180deg);
    }
  }
}
