$left-color: #242e4d;
$right-color: #897e79;
$green-dark: #35c3c1;
$green-light: #00d6b7;
$gray: #8f8f8f;
$gray-light: #f5f6f8;

.authentification {
  position: relative;
  font-size: 1.5em;
  top: -15px;
  left: -15px;
  width: calc(100% + 15px);
  .bg-auth {
    @media (max-width: 767px) {
      display: none;
    }
    background: transparent linear-gradient(91deg, #021b79 0%, #0575e6 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;

    .img {
      display: flex;
      position: absolute;
      top: 25%;
      left: 25%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 50%;
      z-index: 1;
      &.signin {
        background-image: url("/images/shared/signin.svg");
      }
      &.signup {
        background-image: url("/images/shared/signup.svg");
      }
    }
    .img-bg {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 60%;
      height: 70%;
      background: #c0defd 0% 0% no-repeat padding-box;
      opacity: 0.38;
      border-radius: 50%;
    }
  }
  .auth-title {
    text-align: center;
    font-size: 33px;
    font-family: "Arima Madurai", cursive;
    letter-spacing: 0;
    color: #000000;
  }
  .blue-line {
    width: 100px;
    height: 3px;
    background: #0575e6;
    margin: auto;
    border-radius: 3px;
  }
  .login-form {
    width: 100%;
    padding: 2em;
    position: relative;
  }
  .flex-row {
    display: flex;
    border: 1px solid #ececec;
  }
  .lf--label {
    width: 2em;
    display: flex;
    align-items: center;
    justify-content: center;

    background: $gray-light;
    cursor: pointer;
    margin-bottom: 0px !important;
    i {
      color: #b1b7c4;
    }
  }
  .lf--input {
    flex: 1;
    padding: 1em;
    border: 0;
    color: $gray;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }
  select#role {
    padding: 20px;
  }
  .lf--submit {
    display: block;
    padding: 1em;
    width: 100%;

    background: #0575e6;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 0.75em;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(black, 0.2);

    &:focus {
      outline: none;
    }
  }
  .lf--forgot {
    display: block;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 15px;
    color: $green-light;
    font-size: 0.65em;
    text-align: center;
    position: relative;
    cursor: pointer;
    &.hover {
      text-decoration: underline;
    }
  }
  .redirect-text {
    color: $gray;
  }
  ::placeholder {
    color: $gray;
  }
}
