@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.4.3/css/mdb.min.css);
//------- text
.xs-text {
  font-size: 13px;
}

//shadow

.no-shadow {
  box-shadow: none !important;
}
//input
input {
  text-indent: 10px;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search-md"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
textarea.md-textarea {
  background: white;
}

.subscriptionLoader {
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #000000ab;
  z-index: 10;
  text-align: center;
  svg {
    position: relative;
    top: 45%;
  }
}

.custom-access-information {
  padding: 20px;
  margin: 20px;
  background: rgba(251, 150, 120, 0.2);
  border: 1px solid #e78a6e;
  color: #bf725b;
  border-radius: 2px;
}

.custom-box {
  background: white;
  padding: 25px 15px;
  border-radius: 5px;
}

.country-flag,
.lang-menu {
  img {
    width: 20px;
    height: 26px;
    margin-right: 5px;
  }
  span {
    margin-right: 5px;
    font-size: 0.9375rem;
  }
}
.lang-menu div {
  padding: 10px 20px;
  border-bottom: 1px solid #f6f8fa;
  list-style: none;
  &:hover {
    background: #f6f8fa;
    cursor: pointer;
  }
}

.dropdown-menu {
  padding: 0px !important;
  top: 55px !important;
  position: absolute !important;
  left: -80px !important;
}

.copyLink {
  color: #0575e6;
  cursor: pointer;
}

.blue-bg {
  background: #0575e6 !important;
}

.blue-txt {
  color: #0575e6 !important;
}
.blue-border {
  border: 1px solid #0575e6 !important;
}
.blue-border-bottom {
  border-bottom: 1px solid #0575e6 !important;
}
.white-hover:hover {
  background: white !important;
}
.hover {
  cursor: pointer;
}

div.fade.in.modal {
  display: flex !important;
}

.modal-dialog {
  margin: auto;
}

.clickable {
  cursor: pointer;
}
