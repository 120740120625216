section#footer .section-title {
  text-align: center;
  color: #0575e6;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer .green-bg {
  background: #0575e61f;
  border-top: 3px solid #0575e6;
  padding-top: 20px;
}
#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #0575e6;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #0575e6;
}
#footer a {
  color: #0575e6;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
#footer ul.social li {
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}
#footer ul.social li a,
#footer ul.quick-links li a {
  color: #0575e6;
}

#footer ul.social li a:hover {
  color: #0575e6;
}
#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}
#footer ul.quick-links li a i {
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

#footer .footer-title {
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
}

#footer ul li span {
  color: rgba(5, 117, 230, 1);
  font-size: 13px;
  font-weight: 700;
}
