.new-documents {
  .td-icons {
    text-align: center;
  }
  td.td-icons .fa {
    padding: 10px;
    color: white;
    background: #0575e6;
  }
}

.agency-folders {
  td.td-icons .fa {
    color: #0575e6;
  }
}
.agency-folders,
.new-documents {
  td.td-icons .fa {
    margin: 0 2px;
  }
  .folders-title {
    font-family: "Arima Madurai", cursive;
    letter-spacing: 0;
    color: #000000;
    font-size: 30px;
  }
  .btn-folder {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;

    &.f-sell {
      background: #e5f1fd !important;
    }

    &.f-rent {
      background: #e5f1e7 !important;
    }

    padding: 15px 40px;
    border: none;
    border-radius: 5px;
    font-family: "PT Sans", sans-serif;
    font-size: 25px;
    box-shadow: -1px 0px 4px 0 #ccc;
    cursor: pointer;
    color: black;
    &:hover {
      background: #13b4cacc;
    }
  }
  .table-title {
    text-align: left;
    font-size: 20px;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0;
    color: #0575e6;
    text-transform: uppercase;
  }
  .fr-title {
    text-align: left;
    font-size: 20px;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0;
    color: #fff;
    text-transform: uppercase;
  }
  .reset-filter {
    cursor: pointer;
    color: #0575e6;
    &:hover {
      color: #333;
    }
  }
  .basic-table {
    position: relative;
    margin: auto;
    border-collapse: collapse;
    width: 100%;
    border-radius: $borderRadius;
    border: 1px solid #9a9a9a !important;
    thead {
      th {
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        letter-spacing: 0;
        color: #9a9a9a;
        text-transform: uppercase;
        font-size: 15px;
        padding: 10px;
      }
    }

    tbody {
      tr {
        background: #fff;
        border-top: 1px solid #9a9a9a !important;
        .cell {
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          letter-spacing: 0;
          color: #000000;
          span {
            padding: 5px 10px;
            border-radius: 15px;
          }
          &.rent span {
            background: #e5f1e7 !important;
          }
          &.sell span {
            background: #e5f1fd !important;
          }
        }
      }
      tr:hover {
        background: rgba(5, 118, 230, 0.212);
        cursor: pointer;
      }
    }

    .cell {
      position: relative;
      padding: 10px;
    }
  }
  .basic {
    width: 100%;
    // overflow-x: auto;
  }
}

.large-requirements {
  background: white;
  border: 1px solid #0575e6;
  padding: 0 10px 0 10px;
  position: fixed;
  top: 155px;
  .reqs div {
    margin: 10px 0;
  }
  h4 {
    font-family: "Arima Madurai", cursive;
  }
  span {
    font-size: 12px;
    &.badge {
      margin-right: 10px;
      padding: 3px;
      &.required {
        background: #ff3547;
      }
    }
  }
}

.requirements {
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
}

.large-requirements {
  @media (min-width: 768px) {
    display: block;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.invite-input {
  border: 1px solid #e6e6e6 !important;
}

.invite-btn {
  color: white !important;
  margin: 2px 0px 0 0;
}

.md-form label {
  top: 8px !important;
  left: 10px !important;
}

.new-documents {
  .selection {
    width: 22px;
    height: 22px;
    border: 1px solid #0575e6;
    display: inline-block;
    position: relative;
    top: 5px;
    margin-right: 5px;
    &.uni {
      border-radius: 11px;
    }
    &.selected:after {
      background: #0575e6;
      width: 16px;
      height: 16px;
      content: " ";
      position: absolute;
      margin-top: 2px;
      margin-left: 2px;
    }
    &.uni.selected:after {
      border-radius: 10px !important;
    }
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Select some files";
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .target-files i {
    font-size: 20px;
  }

  .target-files .count {
    position: relative;
    top: -10px;
    font-weight: 700;
    font-size: 13px;
  }
  .gray-txt {
    color: #cecece;
  }
}
.file-name {
  font-size: 13px;
  color: #8d8d8d;
}
.switchery {
  border: 1px solid #dee2e6;
}

.folder-status {
  display: block;
  height: 30px;
  .advancement-status {
    position: absolute;
    right: 0;
    select {
      background-color: #e5f1fd;
      padding: 6px 10px 6px 5px;
      width: 100%;
      border: 1px solid #d2e4f7;
      border-radius: 10px;
    }
  }
  .switchery-container.disactivated {
    position: absolute;
    right: 0;
    z-index: 11;
    span {
      top: -10px;
      position: relative;
      font-weight: 600;
      font-size: 18px;
      color: #031c79;
      text-decoration: underline;
    }
  }
}

tr.disable {
  background: #e0e0e8 !important;
  cursor: default !important;
}

.sbs {
  width: 10%;
}

span.unreaden-conv {
  width: 6px !important;
  height: 6px !important;
  position: relative;
  left: -7px;
  top: -5px;
  display: inline-block;
  border-radius: 5px;
  animation: rouge 1s infinite;
  &.true {
    background: #ff3547;
  }
}

@keyframes rouge {
  0% {
    z-index: -1;
  }
  100% {
    z-index: 1;
  }
}
