.Header {
  .navbar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px 0 #eaeaea !important;
  }
  .collapse.navbar-collapse {
    display: flex !important;
    flex-basis: auto !important;
  }
  .navbar-toggler .fa {
    color: #0575e6;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #0575e6;
  &:hover {
    color: #0575e6;
  }
}
