@media(max-width: 500px){
  .body{
    padding: 0 !important;
  }
  .btn-folder{
    margin-bottom: 10px;
  }
  .right-content{
    width: calc(100% - 60px);
  }

  .custom-box{
    background: #f6f8fa;
  }

  .generate-btn{
    min-height: 40px !important;
  }
  .inline-language{
    margin-left: 0 !important;
  }

}
