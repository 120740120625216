@import url(https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arima+Madurai&family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Crete+Round&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.4.3/css/mdb.min.css);
@charset "UTF-8";
body {
  font-family: "PT Sans", sans-serif; }

.arima {
  font-family: "Arima Madurai", cursive; }

.content {
  display: flex;
  min-height: 300px; }

.right-content .body {
  padding: 15px; }

.left-content {
  width: 60px;
  background: white; }
  .left-content .logo {
    display: flex;
    text-decoration: none;
    font-family: "Crete Round", serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 1;
    padding: 5px;
    padding-left: 10px; }
  .left-content .small-logo {
    background-image: url("/images/logo/logoImg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 50px;
    position: relative;
    top: 4px; }
  .left-content .large-logo {
    display: none; }
  .left-content.extend {
    width: 200px; }
    .left-content.extend .large-logo {
      display: block;
      background-image: url("/images/logo/logoText.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 160px; }

.right-content {
  background: #f6f8fa;
  width: 100%; }

.Header .navbar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px 0 #eaeaea !important; }

.Header .collapse.navbar-collapse {
  display: flex !important;
  flex-basis: auto !important; }

.Header .navbar-toggler .fa {
  color: #0575e6; }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important; } }

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #0575e6; }
  .navbar.navbar-dark .breadcrumb .nav-item .nav-link:hover,
  .navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: #0575e6; }

.navigation-menu {
  padding: 40px 0; }
  .navigation-menu li {
    list-style: none; }
    .navigation-menu li a {
      display: flex;
      text-decoration: none;
      color: #8c8c8c;
      padding: 15px; }
      .navigation-menu li a:hover {
        background: #f6f8fa; }
  .navigation-menu .navigation-icon {
    padding: 0 6px;
    position: relative; }
  .navigation-menu .navigation-text {
    display: none !important;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
    letter-spacing: 0;
    font-family: 'PT Sans', sans-serif;
    color: black; }

.extend .navigation-text {
  display: block !important; }

.extend li[data-title]:hover::before, .extend li[data-title]:hover::after {
  display: none !important; }

.navigation-menu li[data-title] {
  position: relative;
  cursor: help; }

.navigation-menu li[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  left: 65px;
  top: 5px;
  display: inline-block;
  padding: 10px;
  border-radius: 2px;
  background: -webkit-linear-gradient(2deg, #13b4ca, #18cabe);
  background: linear-gradient(88deg, #13b4ca, #18cabe);
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  z-index: 5; }

.navigation-menu li[data-title]:hover::after {
  content: '';
  position: absolute;
  left: 49px;
  top: 15px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-right: 8px solid #13b4ca; }

section#footer .section-title {
  text-align: center;
  color: #0575e6;
  margin-bottom: 50px;
  text-transform: uppercase; }

#footer .green-bg {
  background: #0575e61f;
  border-top: 3px solid #0575e6;
  padding-top: 20px; }

#footer h5 {
  padding-left: 10px;
  border-left: 3px solid #0575e6;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #0575e6; }

#footer a {
  color: #0575e6;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

#footer ul.social li {
  padding: 3px 0; }

#footer ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease; }

#footer ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px; }

#footer ul.social li a,
#footer ul.quick-links li a {
  color: #0575e6; }

#footer ul.social li a:hover {
  color: #0575e6; }

#footer ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease; }

#footer ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700; }

#footer ul.quick-links li a i {
  margin-right: 5px; }

#footer ul.quick-links li:hover a i {
  font-weight: 700; }

@media (max-width: 767px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px; } }

#footer .footer-title {
  color: #505050;
  font-size: 14px; }

#footer ul li span {
  color: #0575e6;
  font-size: 13px;
  font-weight: 700; }

.authentification {
  position: relative;
  font-size: 1.5em;
  top: -15px;
  left: -15px;
  width: calc(100% + 15px); }
  .authentification .bg-auth {
    background: transparent -webkit-linear-gradient(359deg, #021b79 0%, #0575e6 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(91deg, #021b79 0%, #0575e6 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070; }
    @media (max-width: 767px) {
      .authentification .bg-auth {
        display: none; } }
    .authentification .bg-auth .img {
      display: flex;
      position: absolute;
      top: 25%;
      left: 25%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 50%;
      z-index: 1; }
      .authentification .bg-auth .img.signin {
        background-image: url("/images/shared/signin.svg"); }
      .authentification .bg-auth .img.signup {
        background-image: url("/images/shared/signup.svg"); }
    .authentification .bg-auth .img-bg {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 60%;
      height: 70%;
      background: #c0defd 0% 0% no-repeat padding-box;
      opacity: 0.38;
      border-radius: 50%; }
  .authentification .auth-title {
    text-align: center;
    font-size: 33px;
    font-family: "Arima Madurai", cursive;
    letter-spacing: 0;
    color: #000000; }
  .authentification .blue-line {
    width: 100px;
    height: 3px;
    background: #0575e6;
    margin: auto;
    border-radius: 3px; }
  .authentification .login-form {
    width: 100%;
    padding: 2em;
    position: relative; }
  .authentification .flex-row {
    display: flex;
    border: 1px solid #ececec; }
  .authentification .lf--label {
    width: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f6f8;
    cursor: pointer;
    margin-bottom: 0px !important; }
    .authentification .lf--label i {
      color: #b1b7c4; }
  .authentification .lf--input {
    flex: 1 1;
    padding: 1em;
    border: 0;
    color: #8f8f8f;
    font-size: 1rem; }
    .authentification .lf--input:focus {
      outline: none; }
  .authentification select#role {
    padding: 20px; }
  .authentification .lf--submit {
    display: block;
    padding: 1em;
    width: 100%;
    background: #0575e6;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 0.75em;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); }
    .authentification .lf--submit:focus {
      outline: none; }
  .authentification .lf--forgot {
    display: block;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 15px;
    color: #00d6b7;
    font-size: 0.65em;
    text-align: center;
    position: relative;
    cursor: pointer; }
    .authentification .lf--forgot.hover {
      text-decoration: underline; }
  .authentification .redirect-text {
    color: #8f8f8f; }
  .authentification ::-webkit-input-placeholder {
    color: #8f8f8f; }
  .authentification ::-moz-placeholder {
    color: #8f8f8f; }
  .authentification :-ms-input-placeholder {
    color: #8f8f8f; }
  .authentification ::-ms-input-placeholder {
    color: #8f8f8f; }
  .authentification ::placeholder {
    color: #8f8f8f; }

.xs-text {
  font-size: 13px; }

.no-shadow {
  box-shadow: none !important; }

input {
  text-indent: 10px; }

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search-md"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
textarea.md-textarea {
  background: white; }

.subscriptionLoader {
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #000000ab;
  z-index: 10;
  text-align: center; }
  .subscriptionLoader svg {
    position: relative;
    top: 45%; }

.custom-access-information {
  padding: 20px;
  margin: 20px;
  background: rgba(251, 150, 120, 0.2);
  border: 1px solid #e78a6e;
  color: #bf725b;
  border-radius: 2px; }

.custom-box {
  background: white;
  padding: 25px 15px;
  border-radius: 5px; }

.country-flag img,
.lang-menu img {
  width: 20px;
  height: 26px;
  margin-right: 5px; }

.country-flag span,
.lang-menu span {
  margin-right: 5px;
  font-size: 0.9375rem; }

.lang-menu div {
  padding: 10px 20px;
  border-bottom: 1px solid #f6f8fa;
  list-style: none; }
  .lang-menu div:hover {
    background: #f6f8fa;
    cursor: pointer; }

.dropdown-menu {
  padding: 0px !important;
  top: 55px !important;
  position: absolute !important;
  left: -80px !important; }

.copyLink {
  color: #0575e6;
  cursor: pointer; }

.blue-bg {
  background: #0575e6 !important; }

.blue-txt {
  color: #0575e6 !important; }

.blue-border {
  border: 1px solid #0575e6 !important; }

.blue-border-bottom {
  border-bottom: 1px solid #0575e6 !important; }

.white-hover:hover {
  background: white !important; }

.hover {
  cursor: pointer; }

div.fade.in.modal {
  display: flex !important; }

.modal-dialog {
  margin: auto; }

.clickable {
  cursor: pointer; }

.Agency .agency-container {
  width: 100%; }
  .Agency .agency-container .agency-sup-information {
    display: none; }
    .Agency .agency-container .agency-sup-information.show {
      display: block; }
  .Agency .agency-container .agency-title {
    text-align: left;
    font-family: "Arima Madurai", cursive;
    letter-spacing: 0;
    color: #000000;
    opacity: 1; }
  .Agency .agency-container .agency-sub-title {
    font-family: "PT Sans", sans-serif; }
  .Agency .agency-container .section-title {
    text-align: left;
    font-family: "PT Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    color: #0575e6;
    text-transform: uppercase; }
  .Agency .agency-container .select {
    border: 1px solid rgba(128, 128, 128, 0.2);
    display: block;
    line-height: 1;
    padding: 10px;
    width: 100%; }

.Agency .agency-toggle {
  text-align: center;
  border: 1px solid #c8c8c8;
  cursor: pointer; }
  .Agency .agency-toggle:hover {
    background: #f2f2f2; }

.Agency .end-subscription {
  color: #13b4ca;
  cursor: pointer;
  font-weight: 400; }
  .Agency .end-subscription:hover {
    text-decoration: underline; }

.Agency label span span {
  color: #ff4444; }

.Agency table.table.orders {
  background: white; }
  .Agency table.table.orders thead {
    border-top: 3px solid #0575e6 !important; }

.Agency .navigation {
  border: 1px solid #0575e6;
  border-radius: 0px; }

.Agency .tabs .tab {
  display: inline-block;
  width: 25%;
  text-align: center;
  background: white;
  padding: 10px 0;
  color: #0575e6;
  font-size: 16px;
  border-left: 1px solid #0575e6;
  border-bottom: 1px solid #0575e6;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 0;
  text-transform: uppercase; }
  .Agency .tabs .tab:first-child {
    border-left: none !important; }
  .Agency .tabs .tab.active {
    color: white;
    background: #0575e6; }
  .Agency .tabs .tab:first-child {
    border-top-left-radius: 0px; }
  .Agency .tabs .tab:last-child {
    border-top-right-radius: 0px; }

.Agency .tabs.full .tab {
  width: 20% !important; }

.Agency .nav-content {
  padding: 25px;
  background: white;
  border-radius: 0px; }
  .Agency .nav-content .content-tab {
    display: none; }
    .Agency .nav-content .content-tab.active {
      display: block; }

.table-filter-icon.selected {
  border-top-color: #fb9678 !important;
  box-shadow: inset 0 4px #fb9678 !important; }

.agency-folders .basic-table thead th {
  border-right: 1px solid; }

.chart-item {
  background: white;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #dee2e6; }
  .chart-item .chart-title {
    color: #333;
    font-weight: 500;
    text-decoration: underline;
    padding-bottom: 25px; }
  .chart-item .chart {
    height: 200px; }
  .chart-item span.chart-number {
    margin-left: 10px;
    background: #333333;
    color: white;
    padding: 0px 5px;
    border-radius: 5px; }

.subscription .price-list {
  margin-top: 30px;
  margin-bottom: 30px; }
  .subscription .price-list .card {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    color: #333;
    position: relative;
    height: auto;
    background: white;
    border-radius: 3px;
    margin: 0 auto;
    padding: 25px 20px;
    box-shadow: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
    border: 1px solid #e2e2e2;
    border-top: 4px solid;
    margin-bottom: 20px; }
    .subscription .price-list .card:hover span.action {
      background: #333333aa;
      color: white; }
    .subscription .price-list .card .switch {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px 15px;
      cursor: pointer;
      background: #8ecdff; }
  .subscription .price-list .col-sell .card,
  .subscription .price-list .col-rent .card {
    border-top-color: #8ecdff; }
    .subscription .price-list .col-sell .card span.action,
    .subscription .price-list .col-rent .card span.action {
      background: #8ecdff;
      color: white; }
    .subscription .price-list .col-sell .card .active.action-container,
    .subscription .price-list .col-rent .card .active.action-container {
      background: #8ecdff63; }
  .subscription .price-list .col-sell_rent .card {
    border-top-color: #fba7ae; }
    .subscription .price-list .col-sell_rent .card span.action {
      background: #fba7ae;
      color: white; }
    .subscription .price-list .col-sell_rent .card .active.action-container {
      background: #fba7ae63; }
  .subscription .price-list .col-sell_rent_media .card {
    border-top-color: #b883ff; }
    .subscription .price-list .col-sell_rent_media .card span.action {
      background: #b883ff;
      color: white; }
    .subscription .price-list .col-sell_rent_media .card .active.action-container {
      background: #b883ff63; }
  .subscription .price-list .title h2 {
    position: relative;
    padding: 0;
    font-size: 28px;
    z-index: 2; }
  .subscription .price-list .price .solde-price {
    display: none; }
  .subscription .price-list .price.solde .solde-price {
    display: inline-block; }
  .subscription .price-list .price.solde .origin-price {
    font-size: 15px;
    text-decoration: line-through; }
  .subscription .price-list .price {
    position: relative;
    z-index: 2; }
    .subscription .price-list .price .total {
      position: relative;
      top: -15px;
      font-size: 13px; }
      .subscription .price-list .price .total .origin-price {
        padding-right: 5px; }
  .subscription .price-list .price h4 .origin-price,
  .subscription .price-list .price h4 .solde-price {
    margin: 0;
    padding: 10px 0 20px 0;
    font-size: 50px; }
  .subscription .price-list .option {
    position: relative;
    z-index: 2;
    display: contents; }
    .subscription .price-list .option .radio-block {
      display: block;
      z-index: 999999;
      cursor: pointer; }
      .subscription .price-list .option .radio-block:hover {
        background: #9eccf133; }
    .subscription .price-list .option span.radio:before {
      content: "";
      position: relative;
      border-color: #495057;
      border-style: solid;
      height: 20px;
      top: 3px;
      width: 20px;
      display: inline-block;
      margin-right: 5px;
      border-radius: 50%; }
    .subscription .price-list .option span.radio.check:before {
      background: #9eccf1; }
    .subscription .price-list .option span.radio.check {
      position: relative; }
      .subscription .price-list .option span.radio.check:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #495057;
        border-radius: 50%;
        left: 4px;
        top: 4.5px; }
  .subscription .price-list .option ul {
    margin: 0;
    padding: 0; }
  .subscription .price-list .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    font-size: 16px; }
    .subscription .price-list .option ul li i {
      margin-right: 5px; }
  .subscription .price-list .card div span.action {
    position: relative;
    z-index: 2;
    width: 185px;
    height: 40px;
    display: block;
    line-height: 40px;
    border-radius: 0px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer; }

.subscription .payement {
  margin-top: 30px;
  margin-bottom: 30px; }
  .subscription .payement .card {
    position: relative;
    height: auto;
    margin: 0 auto;
    box-shadow: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
    border-radius: 3px;
    background: white;
    padding: 25px 20px; }
    .subscription .payement .card.sell, .subscription .payement .card.rent {
      border: none;
      border: 1px solid #e4e4e4;
      border-top: 3px solid #8ecdff; }
    .subscription .payement .card.sell_rent {
      border: none;
      border: 1px solid #e4e4e4;
      border-top: 3px solid #fba7ae; }
    .subscription .payement .card.sell_rent_media {
      border: none;
      border: 1px solid #e4e4e4;
      border-top: 3px solid #b883ff; }

.subscription .card-bottom {
  height: 36px;
  width: 100%;
  max-width: 400px;
  margin-top: 5px;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  background: #556cd6; }

.update-currentOrder {
  background: #18cabe1f;
  padding: 1rem 15px;
  border: 1px solid #0575e6 !important;
  border-top: 3px solid #0575e6 !important;
  margin-bottom: 10px;
  position: relative; }
  @media (max-width: 767px) {
    .update-currentOrder {
      padding-bottom: 70px; } }
  .update-currentOrder .price {
    float: right;
    position: relative;
    top: -40px;
    font-size: 18px;
    font-weight: 700; }
  .update-currentOrder .option {
    display: inline-block; }
    @media (max-width: 767px) {
      .update-currentOrder .option {
        display: block; } }
    .update-currentOrder .option ul {
      list-style-type: none;
      padding-left: 10px;
      margin-bottom: 0; }
      .update-currentOrder .option ul li {
        font-size: 13px;
        color: #8c8c8c;
        font-style: italic; }
        .update-currentOrder .option ul li i {
          margin-right: 5px; }
  .update-currentOrder .upgrade-btn {
    background: #13b4ca;
    padding: 10px;
    width: 180px;
    text-align: center;
    color: white;
    border: 1px solid #0575e6;
    display: inline-block;
    position: absolute;
    right: 15px;
    margin-top: 15px;
    cursor: pointer; }

.subscription-container {
  background: white;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 0px; }

.price-list .special-card {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: center right;
          transform-origin: center right;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  height: 570px; }
  .price-list .special-card.is-flipped {
    -webkit-transform: translateX(-100%) rotateY(-180deg);
            transform: translateX(-100%) rotateY(-180deg); }
  .price-list .special-card .card {
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    width: calc(100% - 30px); }
  .price-list .special-card .card-rent {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  max-width: 400px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #6c757d;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin: auto;
  margin-bottom: 25px; }

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.MyCardElement--invalid {
  border-color: #fa755a; }

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important; }

.stripe-tabs .tab {
  padding: 10px;
  border: 1px solid #0575e6;
  color: #0575e6;
  cursor: pointer; }

.stripe-tabs .tab.active {
  background: #0575e6;
  color: white; }

.sepaForm {
  max-width: 400px;
  margin: auto; }

.mandate-acceptance {
  text-align: justify;
  font-size: 12px; }

.new-documents .td-icons {
  text-align: center; }

.new-documents td.td-icons .fa {
  padding: 10px;
  color: white;
  background: #0575e6; }

.agency-folders td.td-icons .fa {
  color: #0575e6; }

.agency-folders td.td-icons .fa,
.new-documents td.td-icons .fa {
  margin: 0 2px; }

.agency-folders .folders-title,
.new-documents .folders-title {
  font-family: "Arima Madurai", cursive;
  letter-spacing: 0;
  color: #000000;
  font-size: 30px; }

.agency-folders .btn-folder,
.new-documents .btn-folder {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 15px 40px;
  border: none;
  border-radius: 5px;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  box-shadow: -1px 0px 4px 0 #ccc;
  cursor: pointer;
  color: black; }
  .agency-folders .btn-folder.f-sell,
  .new-documents .btn-folder.f-sell {
    background: #e5f1fd !important; }
  .agency-folders .btn-folder.f-rent,
  .new-documents .btn-folder.f-rent {
    background: #e5f1e7 !important; }
  .agency-folders .btn-folder:hover,
  .new-documents .btn-folder:hover {
    background: #13b4cacc; }

.agency-folders .table-title,
.new-documents .table-title {
  text-align: left;
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 0;
  color: #0575e6;
  text-transform: uppercase; }

.agency-folders .fr-title,
.new-documents .fr-title {
  text-align: left;
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase; }

.agency-folders .reset-filter,
.new-documents .reset-filter {
  cursor: pointer;
  color: #0575e6; }
  .agency-folders .reset-filter:hover,
  .new-documents .reset-filter:hover {
    color: #333; }

.agency-folders .basic-table,
.new-documents .basic-table {
  position: relative;
  margin: auto;
  border-collapse: collapse;
  width: 100%;
  border-radius: 0px;
  border: 1px solid #9a9a9a !important; }
  .agency-folders .basic-table thead th,
  .new-documents .basic-table thead th {
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    color: #9a9a9a;
    text-transform: uppercase;
    font-size: 15px;
    padding: 10px; }
  .agency-folders .basic-table tbody tr,
  .new-documents .basic-table tbody tr {
    background: #fff;
    border-top: 1px solid #9a9a9a !important; }
    .agency-folders .basic-table tbody tr .cell,
    .new-documents .basic-table tbody tr .cell {
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      letter-spacing: 0;
      color: #000000; }
      .agency-folders .basic-table tbody tr .cell span,
      .new-documents .basic-table tbody tr .cell span {
        padding: 5px 10px;
        border-radius: 15px; }
      .agency-folders .basic-table tbody tr .cell.rent span,
      .new-documents .basic-table tbody tr .cell.rent span {
        background: #e5f1e7 !important; }
      .agency-folders .basic-table tbody tr .cell.sell span,
      .new-documents .basic-table tbody tr .cell.sell span {
        background: #e5f1fd !important; }
  .agency-folders .basic-table tbody tr:hover,
  .new-documents .basic-table tbody tr:hover {
    background: rgba(5, 118, 230, 0.212);
    cursor: pointer; }
  .agency-folders .basic-table .cell,
  .new-documents .basic-table .cell {
    position: relative;
    padding: 10px; }

.agency-folders .basic,
.new-documents .basic {
  width: 100%; }

.large-requirements {
  background: white;
  border: 1px solid #0575e6;
  padding: 0 10px 0 10px;
  position: fixed;
  top: 155px; }
  .large-requirements .reqs div {
    margin: 10px 0; }
  .large-requirements h4 {
    font-family: "Arima Madurai", cursive; }
  .large-requirements span {
    font-size: 12px; }
    .large-requirements span.badge {
      margin-right: 10px;
      padding: 3px; }
      .large-requirements span.badge.required {
        background: #ff3547; }

@media (min-width: 768px) {
  .requirements {
    display: none; } }

@media (max-width: 767px) {
  .requirements {
    display: block; } }

@media (min-width: 768px) {
  .large-requirements {
    display: block; } }

@media (max-width: 767px) {
  .large-requirements {
    display: none; } }

.invite-input {
  border: 1px solid #e6e6e6 !important; }

.invite-btn {
  color: white !important;
  margin: 2px 0px 0 0; }

.md-form label {
  top: 8px !important;
  left: 10px !important; }

.new-documents .selection {
  width: 22px;
  height: 22px;
  border: 1px solid #0575e6;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px; }
  .new-documents .selection.uni {
    border-radius: 11px; }
  .new-documents .selection.selected:after {
    background: #0575e6;
    width: 16px;
    height: 16px;
    content: " ";
    position: absolute;
    margin-top: 2px;
    margin-left: 2px; }
  .new-documents .selection.uni.selected:after {
    border-radius: 10px !important; }

.new-documents .custom-file-input::-webkit-file-upload-button {
  visibility: hidden; }

.new-documents .custom-file-input {
  color: transparent; }

.new-documents .custom-file-input::-webkit-file-upload-button {
  visibility: hidden; }

.new-documents .custom-file-input::before {
  content: "Select some files";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt; }

.new-documents .custom-file-input:hover::before {
  border-color: black; }

.new-documents .custom-file-input:active {
  outline: 0; }

.new-documents .custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); }

.new-documents .target-files i {
  font-size: 20px; }

.new-documents .target-files .count {
  position: relative;
  top: -10px;
  font-weight: 700;
  font-size: 13px; }

.new-documents .gray-txt {
  color: #cecece; }

.file-name {
  font-size: 13px;
  color: #8d8d8d; }

.switchery {
  border: 1px solid #dee2e6; }

.folder-status {
  display: block;
  height: 30px; }
  .folder-status .advancement-status {
    position: absolute;
    right: 0; }
    .folder-status .advancement-status select {
      background-color: #e5f1fd;
      padding: 6px 10px 6px 5px;
      width: 100%;
      border: 1px solid #d2e4f7;
      border-radius: 10px; }
  .folder-status .switchery-container.disactivated {
    position: absolute;
    right: 0;
    z-index: 11; }
    .folder-status .switchery-container.disactivated span {
      top: -10px;
      position: relative;
      font-weight: 600;
      font-size: 18px;
      color: #031c79;
      text-decoration: underline; }

tr.disable {
  background: #e0e0e8 !important;
  cursor: default !important; }

.sbs {
  width: 10%; }

span.unreaden-conv {
  width: 6px !important;
  height: 6px !important;
  position: relative;
  left: -7px;
  top: -5px;
  display: inline-block;
  border-radius: 5px;
  -webkit-animation: rouge 1s infinite;
          animation: rouge 1s infinite; }
  span.unreaden-conv.true {
    background: #ff3547; }

@-webkit-keyframes rouge {
  0% {
    z-index: -1; }
  100% {
    z-index: 1; } }

@keyframes rouge {
  0% {
    z-index: -1; }
  100% {
    z-index: 1; } }

.agency-sell .sell-title {
  font-size: 22px;
  color: #212529;
  font-family: 'Arima Madurai', cursive; }

.custom-input {
  margin-bottom: 20px; }
  .custom-input div.input {
    position: relative; }
    .custom-input div.input > span.label {
      margin-bottom: 5px;
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
      letter-spacing: 0;
      color: #000000;
      text-transform: uppercase;
      opacity: 1; }
    .custom-input div.input > input[type="text"],
    .custom-input div.input > input[type="email"],
    .custom-input div.input > input[type="number"],
    .custom-input div.input > .react-datepicker-wrapper input[type="text"] {
      padding: 5px 0px 5px 0px;
      background: white;
      border: 1px solid #e6e6e6; }
      .custom-input div.input > input[type="text"]:focus,
      .custom-input div.input > input[type="email"]:focus,
      .custom-input div.input > input[type="number"]:focus,
      .custom-input div.input > .react-datepicker-wrapper input[type="text"]:focus {
        outline: 0;
        background: #0575e6;
        color: white; }
        .custom-input div.input > input[type="text"]:focus + .requirements,
        .custom-input div.input > input[type="email"]:focus + .requirements,
        .custom-input div.input > input[type="number"]:focus + .requirements,
        .custom-input div.input > .react-datepicker-wrapper input[type="text"]:focus + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none; }
    .custom-input div.input > .react-datepicker-wrapper {
      width: 100%; }
    .custom-input div.input > select.select {
      background-color: white;
      padding: 10px 0px 10px 0px;
      width: 100%;
      border: 1px solid #e6e6e6; }
      .custom-input div.input > select.select:focus {
        outline: 0;
        background-color: #0575e6;
        color: white; }
        .custom-input div.input > select.select:focus + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none; }
    .custom-input div.input > .radio {
      background-color: white;
      padding: 10px 10px 10px 10px;
      width: 100%;
      border: 1px solid #e6e6e6; }
      .custom-input div.input > .radio span {
        margin-right: 15px;
        margin-left: 5px; }
      .custom-input div.input > .radio:hover {
        outline: 0;
        background: #0575e6;
        color: white; }
        .custom-input div.input > .radio:hover + .requirements {
          padding: 10px !important;
          background: white;
          position: absolute;
          width: 100%;
          z-index: 99;
          min-height: 198px;
          border: 1px solid #0575e6;
          border-top: none; }
    .custom-input div.input .requirements {
      color: #999;
      -webkit-transition: 0.28s;
      transition: 0.28s;
      max-height: 0;
      overflow: hidden;
      font-style: italic; }
      .custom-input div.input .requirements span.badge {
        margin-right: 10px;
        font-size: 12px !important;
        font-weight: 400;
        padding: 3px !important;
        background: white;
        color: white;
        width: 50px; }
        .custom-input div.input .requirements span.badge.optional {
          background: #e67e22; }
        .custom-input div.input .requirements span.badge.required {
          background: #e74c3c; }

.c-error .c-validation {
  color: #e74c3c !important;
  padding: 3px 10px !important;
  border-radius: 0 !important;
  position: relative;
  display: inline-block !important;
  border: 1px solid #cccccc;
  margin-top: 10px;
  font-size: 12px;
  background: #e4e4e4; }
  .c-error .c-validation:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #cccccc;
    position: absolute;
    top: -10px; }

.c-error .label:after {
  content: "*";
  color: #e74c3c; }

button.submit,
.direction {
  display: block;
  width: 100%;
  padding: 5px 0;
  background: #0575e6;
  color: white;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  border-radius: 0px;
  text-transform: uppercase; }

.direction {
  background: white !important;
  color: #0575e6 !important;
  border: 1px solid #0575e6 !important;
  margin-top: 5px; }
  .direction:hover {
    background: #0575e65a !important;
    color: white !important; }

.no-bg.custom-input div.input > .radio:hover {
  background: #eee !important;
  color: #212529 !important; }

.generate-btn {
  display: flex;
  width: 100%;
  padding: 5px 0;
  background: #031c79;
  color: white;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  margin: 5px 0;
  min-height: 60px;
  border-radius: 0px; }
  .generate-btn.blue-bg {
    background: #0575e6 !important; }
  .generate-btn span {
    margin: auto;
    text-align: center; }

.download-btn {
  padding: 0.5rem 1rem;
  width: 100%;
  background: #00b6e9;
  display: inline-block;
  color: #232323;
  font: 200 20px "Source Sans Pro";
  text-decoration: none;
  text-align: left;
  -webkit-transition: 0.5s linear all;
  transition: 0.5s linear all; }
  .download-btn:hover {
    background: #50d9ff; }
  .download-btn i {
    color: white;
    margin-right: 10px; }

button.validate-btn,
button.remove-btn,
button.add-ecoBen {
  color: white;
  border: none;
  border-radius: 0px;
  width: 200px;
  padding: 5px; }
  button.validate-btn i,
  button.remove-btn i,
  button.add-ecoBen i {
    margin-right: 5px; }

button.add-btn {
  padding: 15px 40px;
  background: #e5f1fd !important;
  border: none;
  border-radius: 5px;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  box-shadow: 0 0 4px 0 #ccc; }
  button.add-btn i {
    margin-right: 5px; }

.validate-benef {
  position: relative;
  width: 100%; }

.validate-benef i {
  float: right;
  padding: 10px 20px;
  border: 1px solid #333;
  background: #8cf0a3;
  color: #333;
  cursor: pointer; }

.inlineFlex {
  display: inline-flex; }

.show-benef i,
.remove-benef i {
  padding: 10px 20px;
  border: 1px solid #333;
  color: #333;
  cursor: pointer;
  display: inline-block; }

.show-benef i {
  background: #e5f1fd; }

.remove-benef i {
  background: #ff3547; }

.benef {
  background: #e5f1fd;
  left: 15px;
  line-height: 2; }

button.add-btn {
  background: #0575e6; }

button.remove-btn {
  color: #ff3547;
  border: 1px solid; }

button.validate-btn {
  color: #0575e6;
  border: 1px solid; }

button.add-ecoBen {
  background: white;
  color: #0575e6;
  border: 1px solid; }

.no-bg,
.inline-language,
.rgpd,
.new-mail {
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Style the checkmark/indicator */
  /* Show the checkmark when checked */ }
  .no-bg .radio-container,
  .inline-language .radio-container,
  .rgpd .radio-container,
  .new-mail .radio-container {
    position: relative;
    padding-left: 24px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .no-bg .radio-container input,
  .inline-language .radio-container input,
  .rgpd .radio-container input,
  .new-mail .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .no-bg .radio-container .checkmark,
  .inline-language .radio-container .checkmark,
  .rgpd .radio-container .checkmark,
  .new-mail .radio-container .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ccc; }
  .no-bg .radio-container:hover input ~ .checkmark,
  .inline-language .radio-container:hover input ~ .checkmark,
  .rgpd .radio-container:hover input ~ .checkmark,
  .new-mail .radio-container:hover input ~ .checkmark {
    background-color: #0575e6; }
  .no-bg .radio-container .checkmark.checked,
  .inline-language .radio-container .checkmark.checked,
  .rgpd .radio-container .checkmark.checked,
  .new-mail .radio-container .checkmark.checked {
    background-color: #0575e6; }
  .no-bg .radio-container .checkmark:after,
  .inline-language .radio-container .checkmark:after,
  .rgpd .radio-container .checkmark:after,
  .new-mail .radio-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none; }
  .no-bg .radio-container .checkmark.checked:after,
  .inline-language .radio-container .checkmark.checked:after,
  .rgpd .radio-container .checkmark.checked:after,
  .new-mail .radio-container .checkmark.checked:after {
    display: block; }

.inline-language {
  margin-left: 15px; }
  .inline-language label {
    margin-right: 15px; }

.no-padding {
  padding: 0px !important; }

.tiny-sb {
  display: grid;
  flex-direction: column;
  grid-template-columns: auto 45px; }

@media (max-width: 500px) {
  .body {
    padding: 0 !important; }
  .btn-folder {
    margin-bottom: 10px; }
  .right-content {
    width: calc(100% - 60px); }
  .custom-box {
    background: #f6f8fa; }
  .generate-btn {
    min-height: 40px !important; }
  .inline-language {
    margin-left: 0 !important; } }

.homePage {
  position: relative; }
  @media (min-width: 500px) {
    .homePage {
      top: -15px;
      left: -15px;
      width: calc(100% + 30px); } }
  .homePage .carousel .slide {
    text-align: inherit !important; }
  .homePage .slider-block {
    position: absolute;
    top: 100px;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .homePage .slider-block {
        top: 30px !important; } }
    @media (max-width: 600px) {
      .homePage .slider-block {
        top: 10px !important;
        margin-left: 10px; } }
    .homePage .slider-block .title {
      font-size: 35px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 20px;
      color: #e67e22;
      letter-spacing: 2px;
      background: #ffffffb3;
      padding: 0 10px; }
      .homePage .slider-block .title span:nth-child(2) {
        color: #2c3e50; }
      @media (max-width: 992px) {
        .homePage .slider-block .title {
          font-size: 28px !important;
          font-weight: 700 !important; } }
      @media (max-width: 767px) {
        .homePage .slider-block .title {
          font-size: 22px !important;
          font-weight: 600 !important; } }
      @media (max-width: 600px) {
        .homePage .slider-block .title {
          font-size: 18px !important;
          font-weight: 600 !important;
          letter-spacing: 1px; } }
      @media (max-width: 500px) {
        .homePage .slider-block .title {
          font-size: 15px !important;
          font-weight: 600 !important;
          letter-spacing: 0.5px; } }
    .homePage .slider-block .list {
      width: 100%; }
      .homePage .slider-block .list ul li {
        font-size: 20px;
        font-weight: 600;
        color: #354b60;
        margin-bottom: 20px; }
        @media (max-width: 767px) {
          .homePage .slider-block .list ul li {
            font-size: 18px !important; } }
        @media (max-width: 600px) {
          .homePage .slider-block .list ul li {
            font-size: 15px !important; } }
        @media (max-width: 500px) {
          .homePage .slider-block .list ul li {
            font-size: 13px !important;
            margin-bottom: 5px; } }
  .homePage .carousel .slide .legend {
    text-decoration: none;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    background: white !important;
    opacity: 1 !important;
    color: #2c3e50 !important;
    font-weight: 500;
    border: 2px solid;
    margin-left: 0 !important;
    left: inherit !important;
    right: 30px; }
  .homePage .section {
    padding: 50px 25px; }
    .homePage .section:nth-child(even) {
      background-color: #f9f9f9; }
    .homePage .section:nth-child(odd) {
      background-color: #fdf3f3; }
  .homePage .silder-top .carousel .slide img {
    max-height: 500px; }
  .homePage .introduction,
  .homePage .concept,
  .homePage .advantages,
  .homePage .subscriptions {
    position: relative; }
    .homePage .introduction .title,
    .homePage .concept .title,
    .homePage .advantages .title,
    .homePage .subscriptions .title {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      color: #e67e22;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 2px; }
      .homePage .introduction .title span:nth-child(2),
      .homePage .concept .title span:nth-child(2),
      .homePage .advantages .title span:nth-child(2),
      .homePage .subscriptions .title span:nth-child(2) {
        color: #2c3e50; }
    .homePage .introduction .body,
    .homePage .concept .body,
    .homePage .advantages .body,
    .homePage .subscriptions .body {
      margin-top: 25px; }
      .homePage .introduction .body p,
      .homePage .concept .body p,
      .homePage .advantages .body p,
      .homePage .subscriptions .body p {
        color: #6f6f6f;
        letter-spacing: 0.5px;
        line-height: 26px;
        text-align: justify; }
    .homePage .introduction img.img,
    .homePage .concept img.img,
    .homePage .advantages img.img,
    .homePage .subscriptions img.img {
      max-height: 245px;
      max-width: 100%; }
  .homePage .color_border {
    background-color: #e67e22;
    height: 3px;
    width: 110px;
    position: relative;
    z-index: 1; }
    .homePage .color_border:before {
      content: "";
      background-color: #fff;
      width: 15px;
      height: 3px;
      top: 0;
      position: absolute;
      right: 30px;
      z-index: 999; }
    .homePage .color_border:after {
      background-color: #2c3e50;
      content: "";
      height: 3px;
      right: 0;
      position: absolute;
      top: 0;
      width: 30px;
      z-index: 9; }
  .homePage .usp .placeholder {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    color: #373737;
    letter-spacing: 0.5px;
    font-size: 18px;
    text-align: center; }
  .homePage .usp .usp-card {
    border-top: 3px solid #0575e6;
    margin: 15px 0;
    padding: 30px 0;
    border-radius: 5px;
    color: #0575e6;
    box-shadow: 1px 2px 4px 1px #cecece;
    height: 326px;
    background: white; }
    .homePage .usp .usp-card:hover {
      background: -webkit-linear-gradient(2deg, #18cabe, #13b4ca);
      background: linear-gradient(88deg, #18cabe, #13b4ca);
      color: white !important; }
    .homePage .usp .usp-card .usp-body {
      padding: 0 20px; }
      .homePage .usp .usp-card .usp-body .fa {
        font-size: 30px;
        margin-top: 5px;
        margin-bottom: 20px;
        padding: 15px;
        border: 1px solid;
        border-radius: 50%; }
      .homePage .usp .usp-card .usp-body .title {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Open Sans", sans-serif;
        -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
        line-height: 21px;
        font-weight: 700; }
      .homePage .usp .usp-card .usp-body .body {
        padding: 0 !important; }
  .homePage .usp .usp-action {
    text-align: center;
    margin-bottom: 25px; }
    .homePage .usp .usp-action .link {
      background: #0575e6;
      color: white;
      padding: 15px;
      display: block;
      text-decoration: none; }
      .homePage .usp .usp-action .link:hover {
        background: -webkit-linear-gradient(2deg, #13b4ca, #18cabe);
        background: linear-gradient(88deg, #13b4ca, #18cabe); }
  .homePage .testimonials {
    background-image: url("/images/sliders/slider1.jpg");
    position: relative;
    height: 425px;
    width: 100%; }
    @media (max-width: 450px) {
      .homePage .testimonials {
        height: 600px; }
        .homePage .testimonials .carousel.slide {
          padding: 0; } }
    @media (max-width: 500px) {
      .homePage .testimonials {
        height: 565px; } }
    @media (max-width: 767px) {
      .homePage .testimonials {
        height: 475px; } }
    .homePage .testimonials .title {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      color: white;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 2px; }
      @media (max-width: 450px) {
        .homePage .testimonials .title {
          font-size: 20px; } }
      .homePage .testimonials .title span:nth-child(2) {
        color: #e67e22; }
    .homePage .testimonials .carousel .carousel-indicators li {
      position: relative;
      top: 30px; }
    .homePage .testimonials .section {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0; }
      @media (max-width: 450px) {
        .homePage .testimonials .section {
          padding-top: 20px; } }
      .homePage .testimonials .section .single_testimonial_area {
        margin: 0 10px; }
        .homePage .testimonials .section .single_testimonial_area .testimonial_author_name {
          text-align: right; }
          .homePage .testimonials .section .single_testimonial_area .testimonial_author_name h5 {
            margin-bottom: 5px;
            letter-spacing: 1px;
            color: #e67e22;
            font-family: "Open Sans", sans-serif;
            font-size: 16px; }
          .homePage .testimonials .section .single_testimonial_area .testimonial_author_name h6 {
            color: #fff;
            font-weight: 300;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin: 0; }
        .homePage .testimonials .section .single_testimonial_area .testimonial_text {
          background-color: #fff;
          border-radius: 6px;
          color: #999;
          margin: 30px 0;
          padding: 40px 30px 30px 30px;
          text-align: center;
          position: relative;
          z-index: 1; }
          @media (max-width: 450px) {
            .homePage .testimonials .section .single_testimonial_area .testimonial_text {
              height: 285px;
              padding: 40px 10px 30px 10px; } }
          .homePage .testimonials .section .single_testimonial_area .testimonial_text p {
            letter-spacing: 0.5px;
            position: relative;
            margin: 0;
            z-index: 1; }
            @media (max-width: 450px) {
              .homePage .testimonials .section .single_testimonial_area .testimonial_text p {
                height: 225px;
                overflow-x: auto; } }
            .homePage .testimonials .section .single_testimonial_area .testimonial_text p:before {
              color: #1abc9c;
              content: "\f10e";
              font-family: fontawesome;
              font-size: 70px;
              position: absolute;
              bottom: -50px;
              right: 0;
              z-index: -5;
              opacity: 0.2; }
          .homePage .testimonials .section .single_testimonial_area .testimonial_text .testimonial_author_thumb {
            height: 80px;
            left: 30px;
            position: absolute;
            top: -40px;
            width: 80px;
            z-index: 9; }
            .homePage .testimonials .section .single_testimonial_area .testimonial_text .testimonial_author_thumb img {
              border-radius: 50%; }
  .homePage .advantages h5,
  .homePage .comparison h5 {
    color: #2c3e50; }
  .homePage .advantages .description,
  .homePage .comparison .description {
    color: #6f6f6f;
    letter-spacing: 0.5px;
    line-height: 26px; }
  .homePage .advantages ul,
  .homePage .comparison ul {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 4px 1px #dee2e6;
    border-top: 3px solid #2c3e50;
    background: white; }
  .homePage .advantages ul li,
  .homePage .comparison ul li {
    letter-spacing: 1px;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 10px 0 10px 40px;
    list-style: none;
    background-image: url("/images/shared/check1.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 25px; }
  .homePage .comparison .comparison-action {
    text-align: center;
    margin-bottom: 25px; }
    .homePage .comparison .comparison-action .link {
      background: #0575e6;
      color: white;
      padding: 15px;
      display: block;
      text-decoration: none; }
      .homePage .comparison .comparison-action .link:hover {
        background: -webkit-linear-gradient(2deg, #13b4ca, #18cabe);
        background: linear-gradient(88deg, #13b4ca, #18cabe); }
  .homePage .comparison .placeholder {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    color: #373737;
    letter-spacing: 0.5px;
    font-size: 18px;
    text-align: center; }
  .homePage .comparison ul {
    margin-top: 30px;
    min-height: 320px;
    padding-top: 30px; }
  .homePage .comparison li {
    letter-spacing: 0.5px !important; }
  .homePage .comparison .negatif {
    border-top: 3px solid #e67e22 !important; }
    .homePage .comparison .negatif li {
      font-size: 13px;
      padding-bottom: 0px !important;
      background-image: url("/images/shared/minus1.png") !important; }
    .homePage .comparison .negatif .sub {
      font-size: 11px;
      color: #7a7a7a;
      padding-left: 40px;
      margin-bottom: 10px; }
  .homePage .comparison .positif li {
    margin-bottom: 20px !important; }
  .homePage .comparison .positif div {
    float: right; }
  .homePage .contact {
    border-bottom: 1px solid #e7862f; }
    .homePage .contact .header {
      background-image: url("/images/shared/contact.jpg");
      height: 150px;
      width: 100%;
      background-size: cover;
      position: relative;
      background-position-y: 80%;
      z-index: 1; }
      .homePage .contact .header:after {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        background-color: rgba(246, 246, 246, 0.7); }
      .homePage .contact .header h3.title {
        color: #e67e22;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        padding: 65px 0px 10px 0px;
        text-align: center;
        text-transform: uppercase; }
    .homePage .contact .information {
      background: #e1e1e2;
      padding: 30px 30px; }
      .homePage .contact .information .section_heading {
        margin-bottom: 15px;
        padding-top: 15px; }
        .homePage .contact .information .section_heading h4 {
          font-family: "Open Sans", sans-serif;
          text-transform: uppercase;
          color: #373737;
          letter-spacing: 1px;
          font-size: 18px; }
      .homePage .contact .information .footer_single_contact_info {
        margin-bottom: 25px;
        position: relative; }
        .homePage .contact .information .footer_single_contact_info i {
          color: #e67e22;
          font-size: 20px;
          position: absolute;
          top: 2px;
          left: 0;
          z-index: 1; }
        .homePage .contact .information .footer_single_contact_info p,
        .homePage .contact .information .footer_single_contact_info a {
          color: #373737;
          display: block;
          font-size: 13px;
          letter-spacing: 1px;
          margin-left: 30px;
          margin-bottom: 0; }
    .homePage .contact .form {
      padding: 40px 30px 10px; }
      .homePage .contact .form input,
      .homePage .contact .form textarea {
        background: #e1e1e2;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border: 1px solid #6c757d;
        color: #e7862f; }
      .homePage .contact .form textarea {
        height: 75px; }
      .homePage .contact .form button {
        background: #e1e1e2;
        padding-left: 35px;
        padding-right: 35px;
        border: 1px solid #6c757d;
        float: right;
        color: #6c757d; }

.custom-files .tabs .tab {
  width: 33.33% !important; }

.custom-files .file-uploader {
  background-color: #0575e60f;
  border-radius: 0px;
  color: #242424;
  border: 2px solid #0575e6; }

.custom-files .custom_file-title {
  text-align: left;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  color: #0575e6;
  text-transform: uppercase;
  background: #f0f7fe;
  border: 1px solid;
  padding: 15px;
  cursor: pointer; }
  .custom-files .custom_file-title.extended:after {
    content: "▲";
    float: right; }
  .custom-files .custom_file-title.not-extended:after {
    content: "▼";
    float: right; }

.custom-files .extended-files {
  display: flex; }

.custom-files .not-extended-files {
  display: none; }

.custom-files form.file-uploader .fa-paperclip {
  color: #0575e6;
  font-size: 26px;
  position: absolute;
  top: 10px;
  right: 35px;
  background: white;
  padding: 7px 10px;
  border: 2px solid;
  border-radius: 50%; }

.custom-files .file-uploader__message-area {
  font-size: 18px;
  padding: 1em;
  text-align: center;
  color: #0575e6;
  height: 90px; }
  .custom-files .file-uploader__message-area p {
    margin-bottom: 0 !important; }
  .custom-files .file-uploader__message-area input {
    color: #0575e6;
    border-color: #0575e6; }

.custom-files .file-list {
  background-color: white;
  font-size: 16px; }

.custom-files .file-list__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.custom-files .file-list li {
  height: 50px;
  line-height: 50px;
  margin-left: 0.5em;
  border: none;
  overflow: hidden; }

.custom-files .removal-button {
  width: 20%;
  border: none;
  background-color: #d65d38;
  color: white; }
  .custom-files .removal-button::before {
    content: "X"; }
  .custom-files .removal-button:focus {
    outline: 0; }

.custom-files .file-chooser {
  padding: 5px 1em;
  height: 75px;
  -webkit-transition: background-color 1s, height 1s;
  transition: background-color 1s, height 1s; }
  .custom-files .file-chooser p {
    font-size: 18px;
    padding-top: 1em; }

.custom-files .file-uploader {
  max-width: 400px;
  height: auto;
  margin: 2em auto; }
  .custom-files .file-uploader * {
    display: block; }
  .custom-files .file-uploader input[type="submit"] {
    margin-top: 0.5em; }

.custom-files .file-list {
  margin: 0 auto;
  max-width: 90%; }

.custom-files .file-list__name {
  max-width: 70%;
  float: left; }

.custom-files .removal-button {
  display: inline-block;
  height: 100%;
  float: right; }

.custom-files .file-chooser {
  margin: 0 auto; }
  .custom-files .file-chooser label {
    margin: 0;
    width: auto;
    max-width: 300px;
    height: auto;
    border: none;
    color: #fff;
    cursor: pointer;
    text-align: center; }
    .custom-files .file-chooser label span {
      padding: 5px;
      background-color: #929292; }
    .custom-files .file-chooser label span.valid {
      background-color: #18cabe; }

.custom-files .file-chooser__input {
  margin: 0 auto;
  display: none; }

.custom-files .file-uploader__submit-button {
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 10px;
  background-color: #0575e6;
  color: white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }
  .custom-files .file-uploader__submit-button:hover {
    opacity: 0.8; }
  .custom-files .file-uploader__submit-button:disabled {
    cursor: not-allowed; }
  .custom-files .file-uploader__submit-button.with-default {
    width: 75% !important;
    display: inline-block !important; }

.custom-files .default-show {
  display: inline-block !important;
  background: #f0f7fe;
  width: 25%;
  padding: 15px;
  text-align: center;
  cursor: pointer; }

.custom-files .file-uploader__actions-buttons,
.custom-files .loading {
  margin-top: 10px; }

.custom-files .file-uploader__actions-buttons span,
.custom-files .file-uploader__actions-buttons a {
  display: inline-block !important;
  width: 33.33%;
  text-align: center;
  border: none;
  font-size: 18px;
  padding: 14px;
  color: #495057;
  cursor: pointer;
  text-decoration: none;
  background: #fff;
  border-top: 2px solid #0575e6; }
  .custom-files .file-uploader__actions-buttons span.edit,
  .custom-files .file-uploader__actions-buttons a.edit {
    color: #ffc107c7;
    border-bottom-left-radius: 0px;
    border-right: 2px solid #0575e6; }
  .custom-files .file-uploader__actions-buttons span.del,
  .custom-files .file-uploader__actions-buttons a.del {
    color: #ff3547c7;
    border-right: 2px solid #0575e6; }
  .custom-files .file-uploader__actions-buttons span.show,
  .custom-files .file-uploader__actions-buttons a.show {
    color: #18cabec7;
    border-bottom-right-radius: 0px; }
  .custom-files .file-uploader__actions-buttons span:hover,
  .custom-files .file-uploader__actions-buttons a:hover {
    opacity: 0.8; }

.custom-files .upload-loading {
  opacity: 0.5; }

.custom-files .loading .spinner {
  background: #f2f2f2;
  text-align: center;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  .custom-files .loading .spinner svg {
    margin: auto; }

.custom-files .upload-spinner svg {
  margin: auto; }

.custom-files .file-list li:after, .custom-files .file-uploader:after {
  content: "";
  display: table;
  clear: both; }

.custom-files .hidden {
  display: none; }
  .custom-files .hidden input {
    display: none; }

.custom-files .error {
  color: #d65d38; }

.custom-files *,
.custom-files *::before,
.custom-files *::after {
  box-sizing: border-box; }

.custom-files ul,
.custom-files li {
  margin: 0;
  padding: 0; }

.file-type-card {
  background: #0575e61f;
  border: 2px solid #0575e6;
  margin: 15px 0;
  padding: 40px 0;
  border-radius: 5px;
  color: #0575e6;
  box-shadow: 1px 2px 4px 1px #cecece;
  cursor: pointer; }
  .file-type-card:hover {
    background: -webkit-linear-gradient(2deg, #18cabe, #13b4ca);
    background: linear-gradient(88deg, #18cabe, #13b4ca);
    color: white !important; }
  .file-type-card a {
    text-decoration: none !important;
    display: block;
    color: #0575e6; }
    .file-type-card a :hover {
      color: white; }
  .file-type-card .card-body {
    text-align: center; }
    .file-type-card .card-body .fa {
      font-size: 40px;
      margin-top: 5px;
      margin-bottom: 20px; }
    .file-type-card .card-body .title {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: "Open Sans", sans-serif;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
      line-height: 21px;
      font-weight: 700; }

.app.mailBox {
  background: var(--background-color);
  border-radius: 7px;
  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.2);
  color: var(--font-color);
  display: grid;
  font-family: Nunito, sans-serif;
  grid-template-rows: auto 1fr;
  height: 100%;
  line-height: 1.5;
  min-width: 780px;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px; }
  .app.mailBox .accounts {
    background: #463350;
    border-bottom-left-radius: 7px;
    padding: 10px; }
  .app.mailBox .accounts__item {
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px;
    position: relative; }
  .app.mailBox .accounts__item:hover {
    background: rgba(255, 106, 136, 0.4); }
  .app.mailBox .accounts__item--active,
  .app.mailBox .accounts__item--active:hover {
    background: #ff6a88; }
  .app.mailBox .accounts__pill {
    position: absolute;
    right: 2px;
    top: 5px; }
  .app.mailBox .accounts__avatar {
    border-radius: 40px;
    width: 40px; }
  .app.mailBox.bonus-russia {
    background: -webkit-linear-gradient(300deg, #e9e9e9, #e9e9e9 33%, #112794 0, #112794 66%, #c51b0e 0, #c51b0e);
    background: linear-gradient(150deg, #e9e9e9, #e9e9e9 33%, #112794 0, #112794 66%, #c51b0e 0, #c51b0e); }
  .app.mailBox.bonus-russia * {
    cursor: url(https://www.larousse.fr/encyclopedie/data/vignettes/1200048-Vladimir_Poutine.jpg) 0 0, auto !important; }
  .app.mailBox.weird-rotate {
    -webkit-animation: weird-rotate 2s linear infinite alternate;
            animation: weird-rotate 2s linear infinite alternate; }

@-webkit-keyframes weird-rotate {
  0% {
    -webkit-transform: scale(0.3) skew(0deg, 3turn);
            transform: scale(0.3) skew(0deg, 3turn); }
  to {
    -webkit-transform: scale(1) skew(1turn, 0deg);
            transform: scale(1) skew(1turn, 0deg); } }

@keyframes weird-rotate {
  0% {
    -webkit-transform: scale(0.3) skew(0deg, 3turn);
            transform: scale(0.3) skew(0deg, 3turn); }
  to {
    -webkit-transform: scale(1) skew(1turn, 0deg);
            transform: scale(1) skew(1turn, 0deg); } }
  .app.mailBox.dark-mode {
    --background-color: #28212b;
    --font-color: #e6e6e6; }
  .app.mailBox.why-so-serious {
    --background-color: #751e9b;
    --font-color: #40ff26;
    font-family: cursive;
    font-size: 1.6em; }
  .app.mailBox.bonus-exit {
    margin-top: 50%;
    -webkit-transform: scale(0) translateY(-200%) rotate(500deg);
            transform: scale(0) translateY(-200%) rotate(500deg);
    -webkit-transition: all, 1s ease;
    transition: all, 1s ease; }
  .app.mailBox .app__content {
    display: grid;
    grid-template-columns: auto minmax(0px, auto) 1fr;
    min-height: 700px; }
  @media (max-width: 1300px) {
    .app.mailBox .app__content {
      grid-template-columns: auto 0px 1fr; } }
  .app.mailBox .app-info {
    background: #c5415b;
    border-radius: 0 50px 50px 0;
    font-family: Nunito, sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    z-index: 1; }
  .app.mailBox .app-info,
  .app.mailBox .app-info a {
    color: var(--background-color); }
  .app.mailBox .app-info--right {
    border-radius: 50px 0 0 50px;
    cursor: pointer;
    left: unset;
    right: 0; }
  .app.mailBox .app-cancel-bonuses {
    background: #ff6a88;
    border: 0;
    border-radius: 200px;
    color: #fff;
    cursor: pointer;
    font-size: 2rem;
    left: 50%;
    outline: 0;
    padding: 10px 50px;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    visibility: hidden;
    z-index: 999999; }
  .app.mailBox .app-cancel-bonuses--active {
    visibility: visible; }
  .app.mailBox .mails {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr;
    background-color: white; }
  @media (max-width: 1020px) {
    .app.mailBox .mails {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr; } }
  .app.mailBox body {
    background-image: -webkit-linear-gradient(left, #ff9a8b, #ff6a88 55%, #ff99ac);
    background-image: linear-gradient(90deg, #ff9a8b, #ff6a88 55%, #ff99ac);
    height: 100vh;
    overflow: hidden;
    padding: 2.5vw; }
  .app.mailBox *,
  .app.mailBox :after,
  .app.mailBox :before {
    box-sizing: border-box;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    scrollbar-width: thin; }
  .app.mailBox ::-webkit-scrollbar {
    width: 10px; }
  .app.mailBox ::-webkit-scrollbar-corner {
    background: transparent; }
  .app.mailBox ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: rgba(120, 120, 120, 0);
    border: 2px solid transparent;
    border-radius: 20px; }
  .app.mailBox button,
  .app.mailBox input,
  .app.mailBox textarea {
    color: #444;
    font-family: Nunito, sans-serif;
    font-size: 1rem;
    line-height: 1.4; }
  .app.mailBox .button {
    background: #ebebeb;
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1;
    outline: 0;
    padding: 10px 25px;
    text-transform: uppercase; }
  .app.mailBox .button--primary {
    background: #ff6a88;
    color: var(--background-color); }
  .app.mailBox .date {
    color: rgba(128, 128, 128, 0.8);
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .dot {
    border-radius: 8px;
    display: inline-block;
    height: 8px;
    margin-right: 3px;
    position: relative;
    top: -1px;
    width: 16px; }
  .app.mailBox .dot--sellers {
    background: #ff6767; }
  .app.mailBox .dot--buyers {
    background: #67dcff; }
  .app.mailBox .dot--notary {
    background: #54dd89; }
  .app.mailBox .dot--yellow {
    background: #ffcf67; }
  .app.mailBox .dot--purple {
    background: #9567ff; }
  .app.mailBox .dot--agency {
    background: #fc84ec; }
  .app.mailBox .scrollable {
    position: relative; }
  .app.mailBox .scrollable__target {
    bottom: 0;
    left: 0;
    overflow: scroll;
    position: relative;
    right: 0;
    top: 0;
    max-height: 600px; }
  .app.mailBox .scrollable-messages {
    display: flex;
    flex-direction: column-reverse; }
  .app.mailBox .message-action {
    background: #463350;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center; }
    .app.mailBox .message-action .account-email {
      color: white; }
    .app.mailBox .message-action i {
      color: #463350; }
  .app.mailBox .message-list {
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    display: grid;
    grid-template-rows: 70px auto 80px; }
  .app.mailBox .message-list > .scrollable__target {
    padding: 15px 5px 15px 15px; }
  .app.mailBox .message-list:hover ::-webkit-scrollbar-thumb {
    background-color: rgba(120, 120, 120, 0.2); }
  .app.mailBox .message {
    background: rgba(128, 128, 128, 0.08);
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-bottom: 0;
    color: rgba(128, 128, 128, 0.9);
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative; }
  .app.mailBox .message:first-of-type {
    border-radius: 5px 5px 0 0; }
  .app.mailBox .message:last-of-type {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 0 0 5px 5px; }
  .app.mailBox .message--new {
    background: var(--background-color);
    color: var(--font-color); }
  .app.mailBox .message--active:before {
    background: #ff6a88;
    border-radius: 5px;
    bottom: 3px;
    content: "";
    left: 3px;
    position: absolute;
    top: 3px;
    width: 4px;
    z-index: 1; }
  .app.mailBox .message__content {
    padding: 15px 15px 15px 0; }
  @media (max-width: 1020px) {
    .app.mailBox .message__content {
      padding: 10px 15px 10px 0; } }
  .app.mailBox .message__exp {
    color: rgba(128, 128, 128, 0.8);
    display: flex;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 700;
    justify-content: space-between; }
  .app.mailBox .message__expr {
    font-size: 0.9rem;
    font-style: italic;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  @media (max-width: 1020px) {
    .app.mailBox .message__expr {
      max-width: 1000px; } }
  .app.mailBox .message__title {
    font-weight: 700; }
  .app.mailBox .message__actions {
    align-content: space-between;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 5px;
    padding: 15px;
    text-align: center;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    visibility: hidden; }
  .app.mailBox .message__icon {
    color: rgba(128, 128, 128, 0.5);
    font-size: 0.9rem; }
  .app.mailBox .message__icon:hover {
    color: var(--font-color); }
  .app.mailBox .message:hover .message__actions {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    visibility: visible; }
  .app.mailBox .message-tags {
    bottom: 2px;
    line-height: 1;
    position: absolute;
    right: 2px; }
  .app.mailBox .menu {
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 0;
    position: relative;
    z-index: 2; }
  @media (max-width: 1300px) {
    .app.mailBox .menu {
      padding: 0px; } }
  .app.mailBox .menu-user {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    margin-bottom: 20px;
    padding: 10px 10px 15px; }
  @media (max-width: 1300px) {
    .app.mailBox .menu-user {
      margin-bottom: 10px;
      padding: 10px 0 15px; }
    .app.mailBox .menu-user .profile-head__mail,
    .app.mailBox .menu-user .profile-head__name {
      display: none; } }
  .app.mailBox .menu-tags {
    font-size: 0.9rem;
    font-weight: 700;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; }
  .app.mailBox .participants-infos {
    padding: 10px;
    align-self: center; }
  @media (max-height: 700px) {
    .app.mailBox .menu-tags {
      display: none; } }
  .app.mailBox .menu-tags__item {
    border-radius: 50px;
    color: rgba(128, 128, 128, 0.8);
    cursor: pointer;
    margin-bottom: 2px;
    padding: 2px 10px;
    list-style: none; }
  .app.mailBox .menu-tags__item:hover {
    background: rgba(128, 128, 128, 0.1); }
  .app.mailBox .menu-main {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2); }
  @media (max-width: 1300px) {
    .app.mailBox .menu-main__pill {
      display: none !important; } }
  .app.mailBox .menu__icon {
    color: rgba(128, 128, 128, 0.4);
    margin-right: 10px; }
  @media (max-width: 1300px) {
    .app.mailBox .menu__icon {
      margin-right: 0; } }
  .app.mailBox .menu__item {
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 15px 30px;
    -webkit-transition: background 0.15s ease;
    transition: background 0.15s ease; }
  @media (max-width: 1300px) {
    .app.mailBox .menu__item {
      display: block;
      font-size: 1.5rem;
      margin-bottom: 5px;
      padding: 5px 10px 7px;
      text-align: center; } }
  .app.mailBox .menu__item:hover {
    background: rgba(128, 128, 128, 0.1); }
  .app.mailBox .menu__item.active {
    background: #ff6a88;
    color: #fff; }
  .app.mailBox .menu__item.active .menu__icon {
    color: rgba(255, 255, 255, 0.8); }
  .app.mailBox .menu__label {
    font-weight: 700; }
  @media (max-width: 1300px) {
    .app.mailBox .menu__label {
      display: none; } }
  @media (max-width: 1300px) {
    .app.mailBox .new {
      text-align: left; } }
  .app.mailBox .new__button {
    background: #ff6a88;
    border: 0;
    border-radius: 50px;
    color: var(--background-color);
    cursor: pointer;
    font-size: 1.6rem;
    height: 50px;
    outline: 0;
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    width: 50px; }
  .app.mailBox .new__button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .app.mailBox .new-mail,
  .app.mailBox .new__button.active {
    -webkit-transform: scale(0);
            transform: scale(0); }
  .app.mailBox .new-mail {
    background: var(--background-color);
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 7px;
    bottom: 20px;
    box-shadow: 0 11px 20px 0 transparent;
    left: 20px;
    opacity: 0;
    position: absolute;
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: visibility, opacity, -webkit-transform;
    transition-property: visibility, opacity, -webkit-transform;
    transition-property: transform, visibility, opacity;
    transition-property: transform, visibility, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
    visibility: hidden;
    width: 600px;
    z-index: 99;
    background: white;
    /* When the checkbox is checked, add a blue background */ }
    .app.mailBox .new-mail .radio-container {
      margin-right: 15px;
      padding-left: 30px !important; }
    .app.mailBox .new-mail .radio-container:hover input ~ .checkmark {
      background-color: #ff6a88 !important; }
    .app.mailBox .new-mail .radio-container .checkmark.checked {
      background-color: #ff6a88 !important; }
  .app.mailBox .error {
    color: #ff6a88;
    font-size: 13px;
    font-weight: 400; }
  .app.mailBox .new-mail__close {
    cursor: pointer;
    padding: 5px; }
  .app.mailBox .new-mail.active {
    box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible; }
  .app.mailBox .new-mail__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 20px; }
  .app.mailBox .new-mail-exp,
  .app.mailBox .new-mail__top {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-weight: 700; }
  .app.mailBox .new-mail-exp {
    background: rgba(128, 128, 128, 0.1);
    color: #636363;
    padding: 20px; }
  .app.mailBox .new-mail-exp__item {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr; }
  .app.mailBox .new-mail-exp__item.subject {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 10px; }
    .app.mailBox .new-mail-exp__item.subject .new-mail-exp__label {
      margin-top: 10px;
      margin-right: 15px;
      margin-bottom: 0px !important; }
  .app.mailBox .new-mail-exp__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    font-weight: 700;
    outline: 0;
    padding: 5px;
    width: 100%;
    font-size: 13px;
    color: #8f8f8f;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding-bottom: 0; }
  .app.mailBox .new-mail-exp__input::-webkit-input-placeholder {
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .new-mail-exp__input::-moz-placeholder {
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .new-mail-exp__input:-ms-input-placeholder {
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .new-mail-exp__input::-ms-input-placeholder {
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .new-mail-exp__input::placeholder {
    color: #9c9c9c;
    font-style: italic;
    font-weight: 400; }
  .app.mailBox .new-mail-exp__label {
    font-size: 0.8rem;
    margin-bottom: 10px; }
  .app.mailBox .radio {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
    .app.mailBox .radio span {
      font-size: 16px; }
  .app.mailBox .new-mail-foot {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px; }
  .app.mailBox .new-mail-foot__icon {
    border-radius: 5px;
    color: #b1b1b1;
    cursor: pointer;
    padding: 10px; }
  .app.mailBox .new-mail-foot__icon:hover {
    background: rgba(0, 0, 0, 0.07);
    color: #6d6d6d; }
  .app.mailBox .new-mail__message {
    background: var(--background-color);
    border: 1px solid transparent;
    border-radius: 5px;
    color: var(--font-color);
    max-height: 50vh;
    min-height: 30vh;
    outline: 0;
    padding: 20px 40px;
    resize: vertical;
    width: 100%; }
  .app.mailBox .paragraph {
    margin: 10px 0;
    white-space: pre-line; }
  .app.mailBox .pill {
    align-items: center;
    background: rgba(128, 128, 128, 0.1);
    border-radius: 30px;
    color: var(--font-color);
    display: flex;
    font-size: 0.7rem;
    font-weight: 700;
    height: 20px;
    line-height: 1;
    padding: 1px 10px 0; }
  .app.mailBox .pill--solid {
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    color: #000; }
  .app.mailBox .preview {
    display: grid;
    grid-template-rows: auto 1fr auto; }
  .app.mailBox .preview:hover ::-webkit-scrollbar-thumb {
    background-color: rgba(120, 120, 120, 0.3); }
  @media (max-width: 1020px) {
    .app.mailBox .preview {
      border-top: 1px solid rgba(128, 128, 128, 0.2); } }
  .app.mailBox .preview__title {
    font-size: 1.6rem;
    font-weight: 700; }
  .app.mailBox .preview-top {
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 10px 20px; }
  .app.mailBox .preview-top__icon {
    color: rgba(128, 128, 128, 0.5);
    cursor: pointer;
    padding: 5px; }
  .app.mailBox .preview-top__icon:hover {
    color: grey; }
  .app.mailBox .preview-content {
    padding: 20px; }
  .app.mailBox .preview-respond {
    background: var(--background-color);
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 5px;
    box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto 20px;
    width: 80%; }
  .app.mailBox .preview-respond__who {
    font-weight: 700; }
  .app.mailBox .preview-respond__who-mail {
    color: #838383;
    font-size: 0.8rem; }
  .app.mailBox .preview-respond__head {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    padding: 10px 20px; }
  .app.mailBox .preview-respond__content {
    padding: 20px 40px; }
  .app.mailBox .preview-foot {
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    display: flex;
    padding: 20px; }
  .app.mailBox .reply-form {
    width: 100%; }
  .app.mailBox .reply-container {
    display: grid;
    grid-template-columns: auto 120px; }
  .app.mailBox .message-input {
    border-color: #e6e6e6;
    border-radius: 3px; }
  .app.mailBox .preview-foot__button {
    margin-left: 10px; }
  .app.mailBox .profile-head {
    align-items: start;
    display: flex;
    justify-content: space-between; }
  .app.mailBox .profile-head__id {
    align-items: center;
    display: flex; }
  .app.mailBox .profile-head__name {
    font-weight: 700; }
  .app.mailBox .profile-head__mail {
    color: rgba(128, 128, 128, 0.8);
    font-size: 0.8rem; }
  .app.mailBox .profile-head__avatar {
    border-radius: 30px;
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 30px; }
  .app.mailBox .select {
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 5px;
    display: inline-block;
    line-height: 1;
    padding-right: 5px;
    padding-top: 3px;
    position: relative;
    padding-bottom: 3px;
    margin-left: 5px; }
  .app.mailBox .select__item {
    all: unset;
    padding: 5px 10px; }
  .app.mailBox .select__arrow {
    position: relative;
    right: 5px;
    top: -2px; }
  .app.mailBox .input {
    background: transparent;
    border: 0;
    font-weight: 700;
    min-width: 350px;
    outline: 0;
    padding: 2px 5px; }
  .app.mailBox .input::-webkit-input-placeholder {
    color: #a3a3a3;
    font-style: italic; }
  .app.mailBox .input::-moz-placeholder {
    color: #a3a3a3;
    font-style: italic; }
  .app.mailBox .input:-ms-input-placeholder {
    color: #a3a3a3;
    font-style: italic; }
  .app.mailBox .input::-ms-input-placeholder {
    color: #a3a3a3;
    font-style: italic; }
  .app.mailBox .input::placeholder {
    color: #a3a3a3;
    font-style: italic; }

.cgu,
.cgv {
  background: white;
  padding: 15px; }

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

.StripeElement {
  border-bottom: inherit;
  border-radius: inherit;
  outline: inherit;
  width: 100%;
  font-size: inherit;
  box-sizing: inherit !important;

  height: 40px !important;
  padding: 10px 12px !important;

  color: #32325d;
  background-color: white !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;

  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
  -webkit-transition: box-shadow 150ms ease !important;
  transition: box-shadow 150ms ease !important;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.sepaBtn {
  height: 36px;
  width: 100%;
  max-width: 400px;
  margin-top: 5px;
  border-radius: 5px;
  color: #fff;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  background: #556cd6;
}

