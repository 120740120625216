$borderRadius: 0px;
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css?family=Karla&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai&family=PT+Sans&display=swap");

body {
  font-family: "PT Sans", sans-serif;
}

.arima {
  font-family: "Arima Madurai", cursive;
}

@import "./contents/css/layout.scss";
@import "./contents/css/header.scss";
@import "./contents/css/sidebar.scss";
@import "./contents/css/footer.scss";

@import "./contents/css/authentifications.scss";
@import "./contents/css/global.scss";
@import "./contents/css/agency.scss";
@import "./contents/css/subscription.scss";
@import "./contents/css/paymentCard.scss";
@import "./contents/css/folders.scss";
@import "./contents/css/sell.scss";
@import "./contents/css/customInput.scss";
@import "./contents/css/small-responsive.scss";
@import "./contents/css/homepage.scss";
@import "./contents/css/files.scss";
@import "./contents/css/mailbox.scss";

.cgu,
.cgv {
  background: white;
  padding: 15px;
}
